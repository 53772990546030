/**
 * Project Page
 * ============
 *
 * List all added stories for Project.
 *
 */

import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import stringFormat from "string-format";

import api, { logoutOnAccessDenied } from "../api";
import Loading from "../components/Loading";
import PageHeader from "../components/PageHeader";
import ProjectBreadcrumbs from "../components/ProjectBreadcrumbs";
import ProjectDescription from "../components/ProjectDescription";
import ProjectName from "../components/ProjectName";
import ProjectStories from "../components/ProjectStories";
import ServerError from "../components/ServerError";
import { AppDataContext } from "../contexts";
import { ProjectWithStories, toProjectWithStories } from "../records";
import { getLocationQuery } from "../utils";

export default withRouter(
  class ProjectPage extends Component {
    static contextType = AppDataContext;

    static propTypes = {
      location: PropTypes.object.isRequired,
      match: PropTypes.object.isRequired
    };

    constructor(props) {
      super(props);

      const query = getLocationQuery(props.location);
      this.state = {
        data: new ProjectWithStories(),
        isAllStories: parseInt(query.all, 10) === 1,
        status: null
      };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      const query = getLocationQuery(nextProps.location);
      const isAllStories = parseInt(query.all) === 1;
      if (isAllStories !== prevState.isAllStories) {
        return {
          ...prevState,
          data: new ProjectWithStories(),
          isAllStories,
          status: null
        };
      }
      return null;
    }

    componentDidMount() {
      this.loadData();
    }

    componentDidUpdate() {
      const { status } = this.state;
      if (status === null) {
        this.loadData();
      }
    }

    getApiUrl = () => {
      const {
        urls: { project: baseUrl }
      } = this.context;
      const {
        match: {
          params: { id: projectId }
        }
      } = this.props;
      return stringFormat(decodeURIComponent(baseUrl), { projectId });
    };

    loadData = () => {
      const { onLogout } = this.context;
      const { isAllStories } = this.state;

      api
        .get(this.getApiUrl(), {
          params: { active: isAllStories ? undefined : 1 }
        })
        .catch(logoutOnAccessDenied(onLogout))
        .then(({ data: apiData }) => {
          this.setState({
            data: toProjectWithStories(apiData),
            status: true
          });
        })
        .catch(() => {
          this.setState({ data: new ProjectWithStories(), status: false });
        });
    };

    render() {
      const { data, isAllStories, status } = this.state;
      const mobileBreadcrumbsContent = (
        <Link className="d-sm-none small" to="/app/projects">
          Back to projects
        </Link>
      );

      let breadcrumbs;
      let content;
      let headerLabel = "Loading";
      let headerProps;

      if (status === null) {
        breadcrumbs = (
          <ProjectBreadcrumbs mobileContent={mobileBreadcrumbsContent} />
        );
        content = <Loading />;
      } else if (status === false) {
        breadcrumbs = (
          <ProjectBreadcrumbs
            label="Error"
            mobileContent={mobileBreadcrumbsContent}
          />
        );
        content = <ServerError>project data</ServerError>;
        headerLabel = "Error";
      } else {
        const { id: projectId } = data;

        breadcrumbs = (
          <ProjectBreadcrumbs
            data={data}
            mobileContent={mobileBreadcrumbsContent}
          />
        );
        content = (
          <Fragment>
            <ProjectDescription className="mt-4 mt-sm-0 mb-4" data={data} />

            <ProjectStories
              className="mt-4 mt-sm-0"
              defaultStories={data.stories}
              isAll={isAllStories}
              projectId={projectId}
              url={`${this.getApiUrl()}/stories`}
            />
          </Fragment>
        );
        headerProps = {
          button: "Edit Project",
          buttonIcon: "edit",
          buttonLink: `/app/projects/${projectId}/edit`
        };
        headerLabel = <ProjectName data={data} />;
      }

      return (
        <div className="container container-main">
          {breadcrumbs}
          <PageHeader isFormNext {...headerProps}>
            {headerLabel}
          </PageHeader>
          {content}
        </div>
      );
    }
  }
);
