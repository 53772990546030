/**
 * Projects Page
 * =============
 *
 * Manage custom projects and stories for user.
 *
 */

import I from "immutable";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import api, { logoutOnAccessDenied } from "../api";
import Loading from "../components/Loading";
import PageHeader from "../components/PageHeader";
import ProjectDescription from "../components/ProjectDescription";
import ProjectName from "../components/ProjectName";
import ServerError from "../components/ServerError";
import { onRefreshDataFactory } from "../components/utils";
import { TRACKER_OWN } from "../constants";
import { AppDataContext } from "../contexts";
import { toProject } from "../records";

export default withRouter(
  class ProjectsPage extends Component {
    static contextType = AppDataContext;

    static propTypes = {
      history: PropTypes.object.isRequired
    };

    state = {
      data: new I.List(),
      status: null
    };

    componentDidMount() {
      this.loadData();
    }

    handleClickFactory = projectUrl => evt => {
      const { history } = this.props;
      if (evt.target.tagName !== "A") {
        history.push(projectUrl);
      }
    };

    colClassNames = "col-12 col-sm-6 col-lg-4 mt-3";

    loadData = () => {
      const {
        onLogout,
        urls: { projects: url }
      } = this.context;
      const toList = apiData => new I.List(apiData.map(toProject));

      api
        .get(url)
        .catch(logoutOnAccessDenied(onLogout))
        .then(({ data: apiData }) => {
          this.setState({ data: toList(apiData), status: true });
        })
        .catch(() => {
          this.setState({ data: new I.List(), status: false });
        });
    };

    renderContent() {
      const { data, status } = this.state;

      if (status === null || status === false) {
        let reason;
        if (status === null) {
          reason = <Loading />;
        } else {
          reason = (
            <ServerError
              className="mb-0"
              onRefreshData={onRefreshDataFactory(this)}
            >
              active projects
            </ServerError>
          );
        }

        return (
          <div className={this.colClassNames}>
            <div className="bg-light card">
              <div className="card-body">{reason}</div>
            </div>
          </div>
        );
      } else if (data.count()) {
        return data.map(this.renderProjectBlock).toArray();
      }

      return null;
    }

    renderProjectBlock = project => {
      const projectName = <ProjectName data={project} />;
      const projectUrl = `/app/projects/${project.id}`;

      return (
        <div className={this.colClassNames} key={`project-${project.id}`}>
          <div className="card bg-light">
            <div
              className="card-body"
              onClick={this.handleClickFactory(projectUrl)}
            >
              <h5 className="card-title mb-0">
                {project.tracker === TRACKER_OWN ? (
                  <Link to={projectUrl}>{projectName}</Link>
                ) : (
                  projectName
                )}
              </h5>
              <ProjectDescription data={project} />
            </div>
          </div>
        </div>
      );
    };

    render() {
      return (
        <div className="container container-main container-projects">
          <PageHeader
            button="New Project"
            buttonIcon="plus"
            buttonLink="/app/projects/add"
          >
            Projects
          </PageHeader>

          <div className="row">{this.renderContent()}</div>

          <p className="text-muted mt-3">
            MoneyTracker allows you to track time spent for working on JIRA
            issues. But sometimes you need to track custom activities, which not
            available anywhere else (say Development Meeting). To achieve this,
            manage your custom projects &amp; stories above.
          </p>
        </div>
      );
    }
  }
);
