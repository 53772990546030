/**
 * Integration Status
 * ------------------
 *
 * Component to show current integration status and refresh it if necessary.
 *
 */

import PropTypes from "prop-types";
import React, { Component } from "react";

import api, { logoutOnAccessDenied } from "../api";
import { AppDataContext } from "../contexts";

import Loading from "./Loading";

export default class IntegrationStatus extends Component {
  static contextType = AppDataContext;

  static propTypes = {
    children: PropTypes.node.isRequired,
    defaultCounter: PropTypes.number,
    tracker: PropTypes.string.isRequired
  };

  static defaultProps = {
    defaultCounter: 0
  };

  constructor(props) {
    super(props);
    this.state = {
      counter: props.defaultCounter,
      isLoading: false
    };
  }

  handleRefresh = evt => {
    evt.preventDefault();
    this.setState({ isLoading: true });

    const {
      onLogout,
      urls: { refreshIntegration: url }
    } = this.context;
    const { tracker } = this.props;

    api
      .post(url, { tracker })
      .catch(logoutOnAccessDenied(onLogout))
      .then(({ data: apiData }) => {
        this.setState({
          counter: apiData.counter,
          isLoading: false
        });
      })
      .catch(() => {
        // TODO: Better error handling
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { counter, isLoading } = this.state;
    if (isLoading) {
      return <Loading isInForm>Refreshing&hellip;</Loading>;
    }

    const { children } = this.props;
    const refreshLink = (
      <a href="#" onClick={this.handleRefresh}>
        force update
      </a>
    );

    return (
      <span className="help-block">
        <strong>Status</strong>: You have <strong>{counter}</strong> active{" "}
        {children} project(s). Available {children} projects will automatically
        update each hour, but if you need you can {refreshLink} {children}{" "}
        projects right now.
      </span>
    );
  }
}
