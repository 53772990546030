/**
 * Server Error
 * ============
 *
 * Show fancy server errors with ability to refresh getting data from server.
 *
 */

import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const ServerError = ({ children, className, isInForm, onRefreshData }) => {
  const { pathname, search } = window.location;
  const currentUrl = search ? `${pathname}?${search}` : pathname;

  return (
    <p
      className={classnames(className, "text-danger", {
        "form-control-plaintext": isInForm
      })}
    >
      Cannot load {children} from server.{" "}
      {onRefreshData ? (
        <a href={currentUrl} onClick={onRefreshData}>
          Refresh
        </a>
      ) : (
        "Please try again later."
      )}
    </p>
  );
};

ServerError.defaultProps = {
  className: null,
  isInForm: false,
  onRefreshData: null
};
ServerError.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isInForm: PropTypes.bool,
  onRefreshData: PropTypes.func
};

export default ServerError;
