import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import I from "immutable";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { StopStoriesFiltersDataPropType } from "../propTypes";
import { hasDateRangeFilter } from "../records";
import { titleize } from "../utils";

import StopStoriesPeriodFilter, {
  getPeriodFilterLabel
} from "./StopStoriesPeriodFilter";
import StopStoriesProjectFilter, {
  getProjectFilterLabel
} from "./StopStoriesProjectFilter";

const State = new I.Record({
  showPeriodFilter: false,
  showProjectFilter: false
});

const StopStoriesFilters = ({ data, projects }) => {
  const hasDateRange = hasDateRangeFilter(data);
  const [state, setState] = useState(
    new State({
      showPeriodFilter: hasDateRange,
      showProjectFilter: !hasDateRange && Boolean(data.project)
    })
  );
  const isDefaultState = state.equals(new State());

  return (
    <div className="bg-light container p-0">
      <div
        className={classnames("px-3", {
          "py-2": isDefaultState,
          "pt-2": !isDefaultState
        })}
      >
        <button
          className="btn btn-link p-0"
          onClick={evt => {
            evt.preventDefault();
            setState(new State({ showPeriodFilter: !state.showPeriodFilter }));
          }}
          type="button"
        >
          {titleize(getPeriodFilterLabel(data))}
          <FontAwesomeIcon
            className="ml-1"
            icon={state.showPeriodFilter ? "caret-up" : "caret-down"}
          />
        </button>
        <button
          className="btn btn-link ml-3 p-0"
          onClick={evt => {
            evt.preventDefault();
            setState(
              new State({ showProjectFilter: !state.showProjectFilter })
            );
          }}
          type="button"
        >
          {getProjectFilterLabel(data, projects)}
          <FontAwesomeIcon
            className="ml-1"
            icon={state.showProjectFilter ? "caret-up" : "caret-down"}
          />
        </button>
      </div>

      {state.showPeriodFilter ? <StopStoriesPeriodFilter data={data} /> : null}
      {state.showProjectFilter ? (
        <StopStoriesProjectFilter data={data} projects={projects} />
      ) : null}
    </div>
  );
};

StopStoriesFilters.propTypes = {
  data: StopStoriesFiltersDataPropType.isRequired,
  projects: PropTypes.instanceOf(I.List).isRequired
};

export default StopStoriesFilters;
