/**
 * Landing Page
 * ============
 *
 * Simple landing page with links to Login methods and base information about
 * project.
 *
 */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";

import ButtonsRow from "../components/ButtonsRow";
import { AppDataContext } from "../contexts";
import { getLocationQuery, getLoginUrl } from "../utils";

const LandingPage = ({ location }) => {
  const query = getLocationQuery(location);

  const loginWithTextNode = (
    <span className="d-inline d-sm-none mr-1">Login with</span>
  );

  return (
    <div className="container">
      <AppDataContext.Consumer>
        {({ urls: { login: loginUrls } }) => (
          <Fragment>
            <div className="d-none d-sm-block mb-3 small text-center text-muted">
              Login With
            </div>
            <ButtonsRow>
              <div className="col-12 col-lg-2 col-sm-4 offset-lg-2">
                <Link
                  className="btn btn-block btn-success"
                  to={{ pathname: "/login/email", query }}
                >
                  <FontAwesomeIcon icon="envelope" />
                  <span className="ml-2">{loginWithTextNode}Email</span>
                </Link>
              </div>
              <div className="col-12 col-lg-2 col-sm-4">
                <a
                  className="btn btn-block btn-primary"
                  href={getLoginUrl(loginUrls.google)}
                >
                  <FontAwesomeIcon icon={["fab", "google"]} />
                  <span className="ml-2">{loginWithTextNode}Google</span>
                </a>
              </div>
              <div className="col-12 col-lg-2 col-sm-4">
                <a
                  className="btn btn-block btn-github"
                  href={getLoginUrl(loginUrls.github)}
                >
                  <FontAwesomeIcon icon={["fab", "github"]} />
                  <span className="ml-2">{loginWithTextNode}GitHub</span>
                </a>
              </div>
              <div className="col-12 col-lg-2 col-sm-4">
                <a
                  className="btn btn-block btn-gitlab"
                  href={getLoginUrl(loginUrls.gitlab)}
                >
                  <FontAwesomeIcon icon={["fab", "gitlab"]} />
                  <span className="ml-2">{loginWithTextNode}Gitlab</span>
                </a>
              </div>
            </ButtonsRow>
          </Fragment>
        )}
      </AppDataContext.Consumer>

      <hr className="my-5" />
      <h2>What is MoneyTracker?</h2>

      <div className="row mt-3">
        <div className="col-12 col-lg-4 col-sm-6">
          <h3>Track Time</h3>
          <p>
            Track spent time while working on JIRA issues, or your own custom
            stories.
          </p>
          <p>
            All you need to have a tab with MoneyTracker opened in your browser,
            no extra applications required.
          </p>
        </div>

        <div className="col-12 col-lg-4 col-sm-6">
          <h3>Reach Targets</h3>
          <p>
            Setup targets for month or custom date range and see your progress
            in realtime.
          </p>
          <p>
            Get a notification to email when your velocity is lower then
            required for completing a target.
          </p>
        </div>

        <div className="col-12 col-lg-4 col-sm-6">
          <h3>Manage Invoices</h3>
          <p>
            Make invoices for whole time tracked in custom date ranges. Invoices
            then could be exported as PDF or CSV.
          </p>
          <p>Automate sending invoices once or twice a month to a customer.</p>
        </div>
      </div>
    </div>
  );
};

LandingPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(LandingPage);
