/**
 * Constants for MoneyTracker UI.
 */

import I from "immutable";

export const DATE_FORMAT = "MMM D YYYY";
export const DATE_TIME_MASK = "YYYY-MM-DDTHH:mm:ssZZ";

export const FALSE_STR = "0";
export const TRUE_STR = "1";

export const KEY_CANCEL = "Escape";
export const KEY_REFRESH = "KeyR";
export const KEY_STOP = "KeyS";
export const KEY_START = "KeyW";

export const INVOICES_LABELS = new I.Map({
  month: "current month",
  "last-month": "last month",
  quarter: "the quarter",
  "half-year": "half a year",
  year: "this year"
});

export const SPAN_LABELS = new I.OrderedMap([
  ["year", "year"],
  ["quarter", "quarter"],
  ["month", "month"],
  ["half-month", "half a month"],
  ["week", "week"],
  ["day", "day"]
]);

export const TRACKER_OWN = "own";
