/**
 * Empty
 * =====
 *
 * Component to show message that required data is not available.
 *
 */

import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const Empty = ({ children, className, isError, isInForm }) => (
  <p
    className={classnames(className, {
      "form-control-plaintext": isInForm,
      "text-danger": isError,
      "text-muted": !isError
    })}
  >
    {children}
  </p>
);

Empty.defaultProps = {
  className: "",
  isError: false,
  isInForm: false
};
Empty.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isError: PropTypes.bool,
  isInForm: PropTypes.bool
};

export default Empty;
