/**
 * Cancel Button
 * =============
 *
 * Standard Cancel button or link displayed inside col container.
 *
 */

import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const CancelButton = ({
  children,
  className,
  colClassName,
  hasOffset,
  isDisabled,
  onClick,
  order,
  to
}) => {
  let button;
  if (onClick === null) {
    button = (
      <Link className={className} to={to}>
        {children}
      </Link>
    );
  } else {
    button = (
      <button
        className={className}
        disabled={isDisabled}
        onClick={onClick}
        type="button"
      >
        {children}
      </button>
    );
  }

  return (
    <div
      className={classnames(colClassName, {
        "offset-lg-4 offset-sm-1": hasOffset,
        [`order-${order}`]: order !== null
      })}
    >
      {button}
    </div>
  );
};

CancelButton.defaultProps = {
  children: "Cancel",
  className: "btn btn-block btn-danger",
  colClassName: "col-12 col-sm-4 col-lg-3",
  hasOffset: true,
  isDisabled: false,
  onClick: null,
  order: null,
  to: null
};
CancelButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  colClassName: PropTypes.string,
  hasOffset: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  order: PropTypes.number,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default CancelButton;
