import PropTypes from "prop-types";

import { FALSE_STR, SPAN_LABELS, TRUE_STR } from "./constants";
import { Project, ProjectWithStories, StopStoriesFiltersData } from "./records";

export const BoolPropType = PropTypes.oneOf([FALSE_STR, TRUE_STR]);

export const ErrorPropType = PropTypes.shape({
  message: PropTypes.string.isRequired
});

export const ProjectPropType = PropTypes.oneOfType([
  PropTypes.instanceOf(Project),
  PropTypes.instanceOf(ProjectWithStories)
]);

export const SpanPropType = PropTypes.oneOf(SPAN_LABELS.keySeq().toArray());

export const StopStoriesFiltersDataPropType = PropTypes.instanceOf(
  StopStoriesFiltersData
);

export const UrlsPropType = PropTypes.shape({
  login: PropTypes.shape({
    github: PropTypes.string.isRequired,
    google: PropTypes.string.isRequired,
    otpRequest: PropTypes.string.isRequired,
    otpValidate: PropTypes.string.isRequired
  }).isRequired,
  logout: PropTypes.string.isRequired,
  notifications: PropTypes.string.isRequired,
  profile: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  projects: PropTypes.string.isRequired,
  refreshIntegration: PropTypes.string.isRequired,
  stopProjects: PropTypes.string.isRequired,
  stopStories: PropTypes.string.isRequired,
  stories: PropTypes.string.isRequired,
  story: PropTypes.string.isRequired,
  targets: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  tracker: PropTypes.string.isRequired,
  trackerStories: PropTypes.string.isRequired
});
