import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import PageHeader from "../components/PageHeader";

const NotFoundPage = ({ isMainContainer }) => (
  <div
    className={classnames("container", { "container-main": isMainContainer })}
  >
    <PageHeader>Not Found</PageHeader>
    <p className="h5 font-weight-normal mt-4">
      Something went wrong and we couldn&#39;t find page you requested.
    </p>
    <p>
      If you&#39;re stuck, try to <Link to="/">go back to main page</Link> or
      contact us by email,{" "}
      <a href="mailto:moneytracker@igordavydenko.com">
        moneytracker@igordavydenko.com
      </a>
      .
    </p>
  </div>
);

NotFoundPage.defaultProps = {
  isMainContainer: true
};

NotFoundPage.propTypes = {
  isMainContainer: PropTypes.bool
};

export default NotFoundPage;
