/**
 * Archive Project
 * ===============
 *
 * Confirm and archive the project.
 *
 */

import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import stringFormat from "string-format";

import api, { logoutOnAccessDenied } from "../api";
import Loading from "../components/Loading";
import PageHeader from "../components/PageHeader";
import ProjectBreadcrumbs from "../components/ProjectBreadcrumbs";
import ServerError from "../components/ServerError";
import { AppDataContext } from "../contexts";
import { toProject } from "../records";

export default withRouter(
  class ProjectArchivePage extends Component {
    static contextType = AppDataContext;

    static propTypes = {
      history: PropTypes.object.isRequired,
      match: PropTypes.object.isRequired
    };

    state = {
      data: null,
      isArchiving: false,
      status: null
    };

    componentDidMount() {
      this.loadData();
    }

    handleArchive = evt => {
      evt.preventDefault();

      const { history } = this.props;

      this.setState({ isArchiving: true }, () => {
        const { onLogout } = this.context;

        api
          .delete(this.getApiUrl(), {
            validateStatus: status => status === 204
          })
          .catch(logoutOnAccessDenied(onLogout))
          .then(() => {
            history.push("/app/projects");
          });
      });
    };

    getApiUrl() {
      const {
        urls: { project: baseUrl }
      } = this.context;
      const {
        match: {
          params: { id: projectId }
        }
      } = this.props;
      return stringFormat(decodeURIComponent(baseUrl), { projectId });
    }

    loadData() {
      api
        .get(this.getApiUrl())
        .then(({ data: apiData }) => {
          this.setState({ data: toProject(apiData), status: true });
        })
        .catch(() => {
          this.setState({ data: null, status: false });
        });
    }

    render() {
      const { data, status } = this.state;

      let breadcrumbs;
      let content;
      let headerLabel;

      if (status === null) {
        breadcrumbs = <ProjectBreadcrumbs />;
        content = <Loading />;
        headerLabel = "Loading";
      } else if (status === false) {
        breadcrumbs = <ProjectBreadcrumbs label="Error" />;
        content = (
          <ServerError onRefreshData={this.handleRefreshData}>
            project data
          </ServerError>
        );
        headerLabel = "Error";
      } else {
        const { isArchiving } = this.state;
        const projectUrl = `/app/projects/${data.id}`;

        breadcrumbs = (
          <ProjectBreadcrumbs
            data={data}
            isLink
            mobileContent={
              <Link className="d-sm-none small" to={projectUrl}>
                Back to {data.name}
              </Link>
            }
          />
        );
        content = (
          <div className="row">
            <div className="col-12 col-sm-8">
              <h3>Are you sure?</h3>
              <p>
                This operation cannot be undone. After archiving, project will
                disappear from UI, but you still be able to make invoices from
                its tracked stories.
              </p>
              <p>
                <button
                  className="btn btn-danger"
                  disabled={isArchiving}
                  onClick={this.handleArchive}
                  type="button"
                >
                  OK! Archive {data.name} project
                </button>{" "}
                <Link className="btn btn-link" to={projectUrl}>
                  Cancel
                </Link>
              </p>
            </div>
          </div>
        );
        headerLabel = `Archive ${data.name}`;
      }

      return (
        <div className="container container-main">
          {breadcrumbs}
          <PageHeader isFormNext>{headerLabel}</PageHeader>
          {content}
        </div>
      );
    }
  }
);
