/**
 * Tracker Stories Select
 * ======================
 *
 * Show Stories available for tracking in HTML select.
 *
 */

import I from "immutable";
import PropTypes from "prop-types";
import React, { Component } from "react";

export default class TrackerStoriesSelect extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onRefreshStories: PropTypes.func.isRequired,
    stories: PropTypes.instanceOf(I.List).isRequired
  };

  renderStoryOption(item) {
    const key = `tracker-story-${item.project.id}-${item.id}`;
    const label = `[${item.slug}] ${item.project.name}: ${item.name}`;
    return (
      <option key={key} value={item.snapshot}>
        {label}
      </option>
    );
  }

  render() {
    const { onChange, onRefreshStories, stories } = this.props;
    return (
      <div>
        <select
          autoFocus
          className="form-control"
          id="tracker-stories"
          onChange={onChange}
          required
        >
          {stories.map(this.renderStoryOption).toArray()}
        </select>
        <span className="form-text text-muted">
          <span className="mr-2">
            Only active (started) Stories shown above. If you Story not listed,
            please make sure you started it in your tracker.
          </span>
          <a href="#" onClick={onRefreshStories}>
            Refresh available Stories.
          </a>
        </span>
      </div>
    );
  }
}
