/**
 * Components Utils
 * ================
 *
 * Common utilities for components, like mixins, but better.
 *
 */

export const onChangeDataFactory = (component, name) => {
  return evt => {
    const { value } = evt.target;
    component.setState(({ data }) => ({
      data: data.updateIn([name], () => value)
    }));
  };
};

export const onRefreshDataFactory = (component, defaultState) => {
  return evt => {
    evt && evt.preventDefault();
    const resetStateTo = defaultState || component.defaultState;
    if (resetStateTo) {
      component.setState(resetStateTo, () => {
        component.loadData();
      });
    } else {
      component.loadData();
    }
  };
};
