/**
 * Tracking Stories Table
 * ======================
 *
 * Show currently tracking stories for user.
 *
 */

import I from "immutable";
import PropTypes from "prop-types";
import React, { Component } from "react";

import { AppDataContext } from "../contexts";
import { humanDelta } from "../utils";

import FilterByProjectLink from "./FilterByProjectLink";
import FilterByStoryLink from "./FilterByStoryLink";

export default class TrackingStoriesTable extends Component {
  static contextType = AppDataContext;

  static propTypes = {
    stories: PropTypes.instanceOf(I.List).isRequired
  };

  renderStoryRow = story => {
    const {
      user: { timezone }
    } = this.context;
    return (
      <tr key={`tracking-story-${story.id}`}>
        <td className="d-none d-sm-table-cell">
          <FilterByStoryLink data={story.story} />
        </td>
        <td className="d-none d-sm-table-cell">
          <FilterByProjectLink data={story.story.project} />
        </td>
        <td>
          <span className="d-inline d-sm-none mr-2">
            [{story.story.slug}] {story.story.project.name}:
          </span>
          {story.story.name}
        </td>
        <td className="d-none d-sm-table-cell">{story.story.labels}</td>
        <td className="text-right" title={humanDelta(story, timezone)}>
          {story.delta}
        </td>
      </tr>
    );
  };

  render() {
    const { stories } = this.props;
    return (
      <table className="table table-hover">
        <thead>
          <tr>
            <th className="d-none d-sm-table-cell" scope="col">
              ID
            </th>
            <th className="d-none d-sm-table-cell" scope="col">
              Project
            </th>
            <th scope="col">Story</th>
            <th className="d-none d-sm-table-cell" scope="col">
              Labels
            </th>
            <th className="text-right" scope="col">
              Time
            </th>
          </tr>
        </thead>
        <tbody>{stories.map(this.renderStoryRow)}</tbody>
      </table>
    );
  }
}
