/**
 * Dashboard Page
 * ==============
 *
 * Show currently tracking stories and stories already tracked for current
 * month for currently logged in User.
 *
 */

import React from "react";

import StopStories from "../components/StopStories";
import Tracker from "../components/Tracker";

const DashboardPage = () => {
  return (
    <div className="container container-main">
      <Tracker />
      <hr className="my-5" />
      <StopStories />
    </div>
  );
};

export default DashboardPage;
