import I from "immutable";

import { FALSE_STR } from "./constants";

export const APIError = new I.Record({
  message: null
});

export const Notification = new I.Record({
  type: null,
  message: null,
  header: null
});

export const Project = new I.Record({
  description: null,
  id: null,
  isActive: true,
  name: null,
  slug: null,
  tracker: null,
  url: null
});

export const ProjectWithStories = new I.Record({
  description: null,
  id: null,
  name: null,
  slug: null,
  stories: new I.List(),
  tracker: null,
  url: null
});

export const StopStories = new I.Record({
  date: null,
  stories: new I.List(),
  total: null
});

export const StopStoriesFiltersData = new I.Record({
  dateFrom: null,
  dateTo: null,
  prev: FALSE_STR,
  project: null,
  span: "month",
  story: null
});

export const ShortStory = new I.Record({
  description: null,
  id: null,
  isActive: true,
  labels: null,
  name: null,
  slug: null,
  tracker: null,
  url: null
});

export const SnapshotStory = new I.Record({
  description: null,
  id: null,
  isActive: true,
  labels: null,
  name: null,
  project: new Project(),
  slug: null,
  snapshot: null,
  tracker: null,
  url: null
});

export const Story = new I.Record({
  description: null,
  id: null,
  isActive: true,
  labels: null,
  name: null,
  project: new Project(),
  slug: null,
  tracker: null,
  url: null
});

export const Target = new I.Record({
  id: null,
  period: null,
  target: null
});

export const TrackedStoryMeta = new I.Record({
  isActive: false,
  isSaving: false
});

export const TrackedStory = new I.Record({
  delta: null,
  id: null,
  meta: new TrackedStoryMeta(),
  startedAt: null,
  stopAt: null,
  lockedAt: null,
  story: new Story()
});

export const User = new I.Record({
  displayName: null,
  email: null,
  id: null,
  jiraExcludeProjects: null,
  jiraIncludeProjects: null,
  jiraUrl: null,
  jiraUsername: null,
  timezone: null
});

export const AppData = new I.Record({
  onLogin: () => {},
  onLogout: () => {},
  onUpdateUser: () => {},
  urls: {},
  user: new User()
});

export const hasDateRangeFilter = ({ dateFrom, dateTo }) =>
  Boolean(dateFrom) || Boolean(dateTo);

export const isAuthenticatedUser = user => user.id !== null;

export const toAPIError = (err, key) => {
  const errMessage = err.toString();
  if (!err.response) {
    return new APIError({ message: errMessage });
  }

  const { data } = err.response;
  const message = data[key] || data.nonFieldErrors || data.detail || errMessage;

  return new APIError({ message });
};

export const toProject = item => new Project(item);

export const toShortStory = item => new ShortStory(item);

export const toTarget = item => new Target(item);

export const toUser = item => new User(item);

export const toProjectWithStories = item =>
  new ProjectWithStories({
    ...item,
    stories: new I.List(item.stories.map(toShortStory))
  });

export const toSnapshotStory = item =>
  new SnapshotStory({
    ...item,
    project: toProject(item.project)
  });

export const toStory = item =>
  new Story({
    ...item,
    project: toProject(item.project)
  });

export const toTrackedStory = item =>
  new TrackedStory({
    ...item,
    story: toStory(item.story)
  });

export const toStopStories = item =>
  new StopStories({
    ...item,
    stories: new I.List(item.stories.map(toTrackedStory))
  });

export const toStopStoriesFiltersData = query =>
  new StopStoriesFiltersData({
    prev: FALSE_STR,
    span: "month",
    ...query,
    dateFrom: query.from,
    dateTo: query.to
  });
