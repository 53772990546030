/**
 * MoneyTracker
 * ============
 *
 * Setup routing and main MoneyTracker application.
 *
 */

import "bootstrap";

import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { render } from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import "./icons";
import api from "./api";
import Header from "./components/Header";
import Footer from "./components/Footer";
import LandingHeader from "./components/LandingHeader";
import PageHeader from "./components/PageHeader";
import ServerError from "./components/ServerError";
import UpdateTimezone from "./components/UpdateTimezone";
import { AppDataContext } from "./contexts";
import DashboardPage from "./pages/DashboardPage";
import HelpPage from "./pages/HelpPage";
import LandingPage from "./pages/LandingPage";
import EmailLoginPage from "./pages/EmailLoginPage";
import NotFoundPage from "./pages/NotFoundPage";
import ProfileFormPage from "./pages/ProfileFormPage";
import ProjectArchivePage from "./pages/ProjectArchivePage";
import ProjectFormPage from "./pages/ProjectFormPage";
import ProjectPage from "./pages/ProjectPage";
import ProjectsPage from "./pages/ProjectsPage";
import TargetsPage from "./pages/TargetsPage";
import TrackerPage from "./pages/TrackerPage";
import { UrlsPropType } from "./propTypes";
import { AppData, isAuthenticatedUser, toUser, User } from "./records";
import { guessNewTimezone } from "./utils";

class App extends Component {
  static propTypes = {
    urls: UrlsPropType.isRequired,
    user: PropTypes.instanceOf(User).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      user: props.user
    };
  }

  handleLogin = user => {
    this.setState({ user });
  };

  handleLogout = user => {
    this.setState({ user: new User() });
  };

  handleUpdateUser = user => {
    this.setState({ user });
  };

  renderApp() {
    const { user } = this.state;
    const newTimezone = guessNewTimezone(user.timezone);

    return (
      <Fragment>
        <Header />

        {Boolean(newTimezone) && (
          <div className="container container-main my-3">
            <UpdateTimezone newTimezone={newTimezone} />
          </div>
        )}

        <Switch>
          <Redirect exact from="/" to="/app" />
          <Route component={DashboardPage} exact path="/app" />
          <Route component={HelpPage} exact path="/app/help" />
          <Route
            exact
            path="/app/profile"
            render={props => <ProfileFormPage {...props} user={user} />}
          />
          <Route component={ProjectsPage} exact path="/app/projects" />
          <Route component={ProjectFormPage} exact path="/app/projects/add" />
          <Route component={ProjectPage} exact path="/app/projects/:id" />
          <Route
            component={ProjectArchivePage}
            exact
            path="/app/projects/:id/archive"
          />
          <Route
            component={ProjectFormPage}
            exact
            path="/app/projects/:id/edit"
          />
          <Route component={TargetsPage} exact path="/app/targets" />
          <Route component={TrackerPage} exact path="/app/tracker" />
          <Route component={NotFoundPage} />
        </Switch>
        <Footer />
      </Fragment>
    );
  }

  renderLanding() {
    return (
      <Fragment>
        <LandingHeader />
        <Switch>
          <Route component={LandingPage} exact path="/" />
          <Redirect from="/app" to="/" />
          <Route component={EmailLoginPage} exact path="/login/email" />
          <Route
            render={props => (
              <NotFoundPage {...props} isMainContainer={false} />
            )}
          />
        </Switch>
        <Footer />
      </Fragment>
    );
  }

  render() {
    const { urls } = this.props;
    const { user } = this.state;

    const appData = new AppData({
      onLogin: this.handleLogin,
      onLogout: this.handleLogout,
      onUpdateUser: this.handleUpdateUser,
      urls,
      user
    });
    const isAuthenticated = isAuthenticatedUser(user);

    return (
      <AppDataContext.Provider value={appData}>
        <BrowserRouter>
          {isAuthenticated ? this.renderApp() : this.renderLanding()}
        </BrowserRouter>
      </AppDataContext.Provider>
    );
  }
}

const run = elem => {
  api
    .get("/")
    .then(({ data: apiData }) => {
      render(<App urls={apiData.urls} user={toUser(apiData.user)} />, elem);
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.error("Unable to render MoneyTracker App", err);
      render(
        <div id="moneytracker-error">
          <div className="container container-main">
            <PageHeader>Error</PageHeader>
            <ServerError>API data</ServerError>
          </div>
          <Footer />
        </div>,
        elem
      );
    });
};

run(document.getElementById("moneytracker"));
