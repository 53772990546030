/**
 * Buttons Row
 * ===========
 *
 * Standart Buttons row to show on each form.
 *
 */

import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const ButtonsRow = ({ children, className }) => (
  <div className={classnames("buttons-xs", "row", className)}>{children}</div>
);

ButtonsRow.defaultProps = {
  className: ""
};
ButtonsRow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default ButtonsRow;
