/**
 * Label
 * -----
 *
 * Unified label component to use across MoneyTracker project.
 *
 */

import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const Label = ({ children, htmlFor, isRequired }) => (
  <label
    className={classnames(
      "col-form-label",
      "col-12",
      "col-lg-2",
      "col-sm-3",
      "text-bold",
      "text-sm-right",
      { "font-weight-bold": isRequired }
    )}
    htmlFor={htmlFor}
  >
    {children}
  </label>
);

Label.defaultProps = {
  isRequired: false
};
Label.propTypes = {
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string.isRequired,
  isRequired: PropTypes.bool
};

export default Label;
