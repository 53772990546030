/**
 * Stop Stroies Table
 * ==================
 *
 * Render stop stories table when user has stop stories for current month.
 *
 */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import I from "immutable";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import qs from "qs";
import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";

import { AppDataContext } from "../contexts";
import { getLocationQuery, humanDelta, isEditableStory } from "../utils";

import DownloadCSVLink from "./DownloadCSVLink";
import FilterByProjectLink from "./FilterByProjectLink";
import FilterByStoryLink from "./FilterByStoryLink";

export default withRouter(
  class StopStoriesTable extends Component {
    static contextType = AppDataContext;

    static propTypes = {
      defaultStories: PropTypes.instanceOf(I.List).isRequired,
      location: PropTypes.object.isRequired,
      onUpdateStory: PropTypes.func.isRequired
    };

    constructor(props) {
      super(props);

      const { defaultStories } = props;
      this.state = {
        defaultStories, // eslint-disable-line react/no-unused-state
        stories: defaultStories
      };
    }

    static getDerivedStateFromProps(props, state) {
      if (props.defaultStories !== state.defaultStories) {
        return {
          defaultStories: props.defaultStories,
          stories: props.defaultStories
        };
      }
      return null;
    }

    componentDidMount() {
      if (this.hasEditableStories()) {
        this.setInterval();
      }
    }

    componentDidUpdate() {
      if (this.hasEditableStories() && !this.intervalId) {
        this.setInterval();
      }
    }

    componentWillUnmount() {
      this.clearInterval();
    }

    handleCheckEditableStory = () => {
      if (!this.hasEditableStories()) {
        this.forceUpdate();
        this.clearInterval();
      }
    };

    handleStoryDeltaActiveFactory = (dayIdx, storyIdx) => {
      if (!isEditableStory(this.getStory(dayIdx, storyIdx))) {
        return null;
      }

      const path = [dayIdx, "stories", storyIdx, "meta", "isActive"];
      return evt => {
        evt.preventDefault();
        this.setState(({ stories }) => ({
          stories: stories.updateIn(path, value => !value)
        }));
      };
    };

    handleStoryDeltaChangeFactory = (dayIdx, storyIdx) => {
      if (!isEditableStory(this.getStory(dayIdx, storyIdx))) {
        return null;
      }

      const path = [dayIdx, "stories", storyIdx, "delta"];
      return evt => {
        const { value } = evt.target;
        this.setState(({ stories }) => ({
          stories: stories.updateIn(path, () => value)
        }));
      };
    };

    handleStoryDeltaSaveFactory = (dayIdx, storyIdx) => {
      const path = [dayIdx, "stories", storyIdx];

      if (!isEditableStory(this.getStory(dayIdx, storyIdx))) {
        const { stories } = this.state;
        const activePath = [...path, "meta", "isActive"];

        if (stories.getIn(activePath)) {
          this.setState({
            stories: stories.updateIn(activePath, () => false)
          });
        }

        return null;
      }

      return evt => {
        if (evt.key && evt.key === "Escape") {
          this.setState(({ stories }) => ({
            stories: stories.updateIn(
              [...path, "meta", "isActive"],
              () => false
            )
          }));
          return;
        }

        if (evt.key && evt.key !== "Enter") {
          return;
        }

        const { onUpdateStory } = this.props;
        const { stories } = this.state;
        const story = stories.getIn(path);

        if (story.meta.isSaving) {
          return;
        }

        this.setState(
          ({ stories }) => ({
            stories: stories.updateIn([...path, "meta", "isSaving"], () => true)
          }),
          () => {
            onUpdateStory({
              id: story.id,
              delta: story.delta
            });
          }
        );
      };
    };

    clearInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    }

    hasEditableStories() {
      const { stories } = this.state;
      return stories
        .map(({ stories }) => stories)
        .flatten(true)
        .some(isEditableStory);
    }

    getStory(dayIdx, storyIdx) {
      const { stories } = this.state;
      return stories.getIn([dayIdx, "stories", storyIdx]);
    }

    setInterval() {
      this.intervalId = setInterval(this.handleCheckEditableStory, 30000);
    }

    renderDayRow = (day, dayIdx) => {
      const {
        user: { timezone }
      } = this.context;

      const { stories } = day;
      const label = moment(day.date, "YYYY-MM-DD").format("ddd, D MMM");
      const total = stories.count();
      const title = `Total: ${day.total}`;

      return stories.map((story, storyIdx) => (
        <tr key={`stop-story-${story.id}`}>
          {!storyIdx ? (
            <td rowSpan={total} title={title}>
              {label}
            </td>
          ) : null}
          <td className="d-none d-sm-table-cell">
            <FilterByStoryLink data={story.story} />
          </td>
          <td className="d-none d-sm-table-cell">
            <FilterByProjectLink data={story.story.project} />
          </td>
          <td>
            <span className="d-inline d-sm-none mr-2">
              [{story.story.slug}] {story.story.project.name}:
            </span>
            {story.story.name}
          </td>
          <td
            className="text-right"
            onClick={this.handleStoryDeltaActiveFactory(dayIdx, storyIdx)}
            style={isEditableStory(story) ? { cursor: "pointer" } : {}}
            title={humanDelta(story, timezone)}
          >
            {!story.meta.isActive &&
            !story.meta.isSaving &&
            isEditableStory(story) ? (
              <small className="mr-1 text-muted">
                <FontAwesomeIcon icon="edit" />
              </small>
            ) : null}
            {story.meta.isSaving ? (
              <small className="text-muted">
                <FontAwesomeIcon icon="circle-notch" spin />
              </small>
            ) : null}
            {story.meta.isActive
              ? this.renderStoryDeltaInput(dayIdx, storyIdx)
              : story.delta}
          </td>
        </tr>
      ));
    };

    renderStoryDeltaInput(dayIdx, storyIdx) {
      const { stories } = this.state;
      return (
        <input
          autoFocus
          className="form-control form-control-sm ml-2 pb-0 pt-0"
          onBlur={this.handleStoryDeltaSaveFactory(dayIdx, storyIdx)}
          onChange={this.handleStoryDeltaChangeFactory(dayIdx, storyIdx)}
          onClick={evt => {
            evt.stopPropagation();
          }}
          onKeyDown={this.handleStoryDeltaSaveFactory(dayIdx, storyIdx)}
          style={{
            display: "inline-block",
            width: "5em"
          }}
          value={stories.getIn([dayIdx, "stories", storyIdx, "delta"])}
        />
      );
    }

    render() {
      const {
        location,
        location: { pathname }
      } = this.props;
      const { stories } = this.state;

      const query = getLocationQuery(location);

      return (
        <div id="stop-stories">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">Day</th>
                <th className="d-none d-sm-table-cell" scope="col">
                  ID
                </th>
                <th className="d-none d-sm-table-cell" scope="col">
                  Project
                </th>
                <th scope="col">Story</th>
                <th className="text-right" scope="col">
                  Delta
                </th>
              </tr>
            </thead>
            <tbody>
              {stories.butLast().map(this.renderDayRow)}
              <tr className="active">
                <td>
                  <DownloadCSVLink data={stories} />
                </td>
                <td className="d-none d-sm-table-cell" />
                <td className="d-none d-sm-table-cell" />
                <td className="text-right">
                  <strong>Total:</strong>
                </td>
                <td className="text-right">{stories.last().total}</td>
              </tr>
            </tbody>
          </table>

          <p className="text-muted">
            <strong className="mr-2">Note:</strong>
            Only stop (<i>tracked</i>) stories shown above. Current tracking
            story doesn&#39;t apply to the table.
            {query.story ? (
              <Fragment>
                {" "}
                <Link
                  to={{
                    pathname,
                    search: `?${qs.stringify({ ...query, story: undefined })}`
                  }}
                >
                  Show all tracked stories by the project
                </Link>
                , not only selected one.
              </Fragment>
            ) : null}
          </p>
        </div>
      );
    }
  }
);
