/**
 * Project Description
 * ===================
 *
 * Render project description with Markdown, when present.
 *
 */

import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Markdown from "react-markdown";

import { ProjectPropType } from "../propTypes";

const ProjectDescription = ({ className, data: { description } }) =>
  description ? (
    <div className={classnames(className, "markdown", "small", "text-muted")}>
      <Markdown>{description}</Markdown>
    </div>
  ) : null;

ProjectDescription.defaultProps = {
  className: null
};

ProjectDescription.propTypes = {
  className: PropTypes.string,
  data: ProjectPropType.isRequired
};

export default ProjectDescription;
