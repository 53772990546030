/**
 * Download CSV Link
 * -----------------
 *
 * If there are stories - render download CSV link.
 *
 */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import I from "immutable";
import moment from "moment-timezone";
import Papa from "papaparse";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { DATE_TIME_MASK } from "../constants";
import { AppDataContext } from "../contexts";
import { getLocationQuery } from "../utils";

export default withRouter(
  class DownloadCSVLink extends Component {
    static contextType = AppDataContext;

    static propTypes = {
      data: PropTypes.instanceOf(I.List).isRequired,
      location: PropTypes.object.isRequired
    };

    get fileName() {
      const {
        user: { timezone }
      } = this.context;
      const { location } = this.props;
      const query = getLocationQuery(location);

      const parts = [];
      if (query.story) {
        parts.push(query.story);
      } else if (query.project) {
        parts.push(query.project);
      }

      if (query.span) {
        parts.push(query.span);
      }

      if (!parts.length) {
        parts.push("all", "month");
      }

      const date = moment().tz(timezone);
      parts.push(date.format("YYYY-MM-DD"), date.format("HH-mm"));
      return `${parts.join("_")}.csv`;
    }

    get url() {
      const {
        user: { timezone }
      } = this.context;
      const { data } = this.props;
      if (!data.butLast().count()) {
        return null;
      }

      const csvDayData = data.butLast().reduce(
        (acc, day) =>
          acc.concat(
            day.stories.map((story, storyIdx) => ({
              Date: !storyIdx ? day.date : null,
              ID: story.story.slug,
              Project: story.story.project.name,
              Story: story.story.name,
              "Start Time": moment(story.startedAt, DATE_TIME_MASK)
                .tz(timezone)
                .format("HH:mm"),
              "End Time": moment(story.stopAt, DATE_TIME_MASK)
                .tz(timezone)
                .format("HH:mm"),
              Delta: story.delta
            }))
          ),
        new I.List()
      );
      const csvData = csvDayData.push({
        "End Time": "Total:",
        Delta: data.last().total
      });

      const csv = Papa.unparse(csvData.toArray());
      const blob = new Blob([csv], {
        type: "text/csv; charset=utf-8;"
      });

      return window.URL.createObjectURL(blob);
    }

    render() {
      const { data } = this.props;
      if (!data.butLast().count()) {
        return null;
      }

      return (
        <a download={this.fileName} href={this.url}>
          <FontAwesomeIcon icon="download" />
          <span className="d-none d-sm-inline ml-2">Download CSV</span>
        </a>
      );
    }
  }
);
