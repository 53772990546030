/**
 * Breadcrumbs from Projects pages
 * ===============================
 *
 * Provide breadcrumbs for,
 *
 * - Project page
 * - Archive project page
 * - Edit project page
 * - Add project page
 *
 */

import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import { ProjectPropType } from "../propTypes";

const ProjectBreadcrumbs = ({
  className,
  data,
  isLink,
  label,
  mobileContent,
  onClick,
  rootClassName
}) => {
  const items = [
    { key: "app", text: "MoneyTracker", to: "/app" },
    { key: "category", text: "Projects", to: "/app/projects" }
  ];

  if (!data) {
    items.push({ key: "page", text: label });
  } else if (isLink) {
    items.push({
      key: "page",
      text: data.name,
      to: `/app/projects/${data.id}`
    });
  } else {
    items.push({ key: "page", text: data.name });
  }

  const lastIdx = items.length - 1;
  items[lastIdx].className = "active";

  return (
    <nav aria-label="breadcrumb" className={classnames("mt-3", rootClassName)}>
      <ol
        className={classnames(
          className,
          "bg-light",
          "breadcrumb",
          "mb-0",
          "d-none",
          "d-sm-flex"
        )}
      >
        {items.map(({ className: itemClassName, key, to, text }, idx) => (
          <li
            className={classnames("breadcrumb-item", itemClassName)}
            key={key}
          >
            {to ? (
              <Link onClick={idx === lastIdx ? onClick : null} to={to}>
                {text}
              </Link>
            ) : (
              text
            )}
          </li>
        ))}
      </ol>
      {mobileContent}
    </nav>
  );
};

ProjectBreadcrumbs.defaultProps = {
  className: null,
  data: null,
  isLink: false,
  label: "Loading",
  mobileContent: null,
  onClick: null,
  rootClassName: null
};

ProjectBreadcrumbs.propTypes = {
  className: PropTypes.string,
  data: ProjectPropType,
  isLink: PropTypes.bool,
  label: PropTypes.string,
  mobileContent: PropTypes.node,
  onClick: PropTypes.func,
  rootClassName: PropTypes.string
};

export default ProjectBreadcrumbs;
