/**
 * Filter by Project Link
 * ----------------------
 *
 * Link to filter stop stories by given project.
 *
 */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import qs from "qs";
import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";

import { Project } from "../records";
import { getLocationQuery, isOwnProject } from "../utils";

const FilterByProjectLink = ({ data, location, location: { pathname } }) => {
  const query = getLocationQuery(location);
  const { id, name } = data;

  const externalLink = !isOwnProject(data) ? (
    <small style={{ marginLeft: "5px" }}>
      <a
        href={data.url}
        rel="noopener noreferrer"
        target="_blank"
        title={`Show project in external tracker: ${data.tracker}`}
      >
        <FontAwesomeIcon icon="external-link-alt" />
      </a>
    </small>
  ) : null;

  return (
    <Fragment>
      <Link
        title={`Show time spent for ${name} project`}
        to={{
          pathname,
          search: `?${qs.stringify({ ...query, project: id })}`
        }}
      >
        {name}
      </Link>
      {externalLink}
    </Fragment>
  );
};

FilterByProjectLink.propTypes = {
  data: PropTypes.instanceOf(Project).isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(FilterByProjectLink);
