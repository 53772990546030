/**
 * Targets Table
 * =============
 *
 * Attempts to load and show targets added by current user.
 *
 */

import I from "immutable";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import api, { logoutOnAccessDenied } from "../api";
import { AppDataContext } from "../contexts";
import { toTarget } from "../records";

import Empty from "./Empty";
import Loading from "./Loading";
import ServerError from "./ServerError";
import { onRefreshDataFactory } from "./utils";

export default class TargetsTable extends Component {
  static contextType = AppDataContext;

  state = {
    data: new I.List(),
    status: null
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const {
      onLogout,
      urls: { targets: url }
    } = this.context;
    const toList = apiData => new I.List(apiData.map(toTarget));

    api
      .get(url)
      .catch(logoutOnAccessDenied(onLogout))
      .then(({ data: apiData }) => {
        this.setState({ data: toList(apiData), status: true });
      })
      .catch(() => {
        this.setState({ data: new I.List(), status: false });
      });
  };

  renderContent() {
    const { data, status } = this.state;
    const hasData = data.count();

    if (status === null || status === false || !hasData) {
      let extraClassName;
      let reason;

      if (status === null) {
        reason = <Loading />;
      } else if (!hasData) {
        reason = (
          <Empty className="m-0">
            No targets added yet.{" "}
            <Link to="/app/targets/add">Add new target</Link> to start
            MoneyTracker helps you control your working hours.
          </Empty>
        );
      } else {
        extraClassName = "table-danger";
        reason = (
          <ServerError onRefreshData={onRefreshDataFactory(this)}>
            targets
          </ServerError>
        );
      }

      return (
        <tr className={extraClassName}>
          <td colSpan={4}>{reason}</td>
        </tr>
      );
    }

    return data.map(this.renderTargetRow).toArray();
  }

  renderTargetRow = target => {
    return (
      <tr key={`target-${target.id}`}>
        <th scope="row">{target.period}</th>
        <td>Work {target.target} hours</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
    );
  };

  render() {
    return (
      <table className="mt-4 table table-hover">
        <thead>
          <tr>
            <th scope="col">Period</th>
            <th scope="col">Target</th>
            <th scope="col">Results</th>
            <th className="text-center" scope="col">
              Active?
            </th>
          </tr>
        </thead>
        <tbody>{this.renderContent()}</tbody>
      </table>
    );
  }
}
