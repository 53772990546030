/**
 * Header
 * ======
 *
 * Header component to be shown only for authenticated users.
 *
 */

import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import api, { logoutOnAccessDenied } from "../api";
import { AppDataContext } from "../contexts";

import Logo from "./Logo";

export default withRouter(
  class Header extends Component {
    static contextType = AppDataContext;

    static propTypes = {
      location: PropTypes.object.isRequired
    };

    navbarTogglerEl = null;

    handleLinkClick = () => {
      const { navbarTogglerEl: el } = this;
      if (el && window.matchMedia("(max-width: 768px)").matches) {
        el.click();
      }
    };

    handleLogout = evt => {
      evt.preventDefault();

      const {
        onLogout,
        urls: { logout: url }
      } = this.context;

      api
        .post(url, undefined, {
          validateStatus: status => status === 204
        })
        .catch(logoutOnAccessDenied(onLogout))
        .then(() => {
          onLogout();
        });
    };

    render() {
      const { user } = this.context;
      const {
        location: { pathname }
      } = this.props;

      const navbarClassNames = classnames(
        "bg-light",
        "border",
        "border-left-0",
        "border-right-0",
        "border-top-0",
        "fixed-top",
        "navbar",
        "navbar-expand-md",
        "navbar-light"
      );

      const projectsClassNames = classnames("nav-item", {
        active: pathname === "/app/projects"
      });
      const targetsClassNames = classnames("nav-item", {
        active: pathname === "/app/targets"
      });

      const helpClassNames = classnames("dropdown-item", {
        active: pathname === "/app/help"
      });
      const profileClassNames = classnames("dropdown-item", {
        active: pathname === "/app/profile"
      });

      return (
        <header className={navbarClassNames}>
          <div className="container">
            <Link className="navbar-brand" title="MoneyTracker" to="/app">
              <span style={{ fontSize: "x-large" }}>
                <Logo />
              </span>
              <span className="d-sm-none ml-2">MoneyTracker</span>
            </Link>
            <button
              aria-controls="navbar-content"
              aria-expanded="false"
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-target="#navbar-content"
              data-toggle="collapse"
              ref={el => {
                this.navbarTogglerEl = el;
              }}
              type="button"
            >
              <span className="navbar-toggler-icon" />
            </button>

            <div className="collapse navbar-collapse" id="navbar-content">
              <ul className="navbar-nav">
                <li className={projectsClassNames}>
                  <Link
                    className="nav-link"
                    onClick={this.handleLinkClick}
                    to="/app/projects"
                  >
                    Projects
                  </Link>
                </li>
                <li className={targetsClassNames}>
                  <Link
                    className="nav-link"
                    onClick={this.handleLinkClick}
                    to="/app/targets"
                  >
                    Targets
                  </Link>
                </li>
              </ul>
              <ul className="navbar-nav ml-auto">
                <li className="dropdown nav-item">
                  <a
                    aria-expanded="false"
                    aria-haspopup="true"
                    className="dropdown-toggle nav-link"
                    data-toggle="dropdown"
                    href="#"
                    id="navbar-dropdown-link"
                    role="button"
                  >
                    {user.displayName || user.email}
                  </a>
                  <div
                    aria-labelledby="navbar-dropdown-link"
                    className="dropdown-menu"
                    role="menu"
                  >
                    <Link
                      className={helpClassNames}
                      onClick={this.handleLinkClick}
                      to="/app/help"
                    >
                      Help
                    </Link>
                    <Link
                      className={profileClassNames}
                      onClick={this.handleLinkClick}
                      to="/app/profile"
                    >
                      Settings
                    </Link>
                    <div className="dropdown-divider d-none-xs" />
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={this.handleLogout}
                    >
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </header>
      );
    }
  }
);
