/**
 * Loading
 * =======
 *
 * Component to show loading spinner.
 *
 */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { createElement } from "react";

const Loading = ({ as, children, className, isInForm }) =>
  createElement(
    as,
    {
      className: classnames(className, "text-muted", {
        "form-control-plaintext": isInForm
      })
    },
    <FontAwesomeIcon icon="circle-notch" spin />,
    " ",
    children
  );

Loading.defaultProps = {
  as: "div",
  children: "Loading…",
  className: "",
  isInForm: false
};

Loading.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  isInForm: PropTypes.bool
};

export default Loading;
