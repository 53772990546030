/**
 * Project Form
 * ============
 *
 * Add or Edit Project details.
 *
 */

import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import api, { logoutOnAccessDenied } from "../api";
import ButtonsRow from "../components/ButtonsRow";
import CancelButton from "../components/CancelButton";
import HelpText from "../components/HelpText";
import InputContainer from "../components/InputContainer";
import Label from "../components/Label";
import PageHeader from "../components/PageHeader";
import ProjectBreadcrumbs from "../components/ProjectBreadcrumbs";
import ProjectDescription from "../components/ProjectDescription";
import SubmitButton from "../components/SubmitButton";
import { onChangeDataFactory } from "../components/utils";
import { AppDataContext } from "../contexts";
import { Project, toProject } from "../records";

export default class ProjectForm extends Component {
  static contextType = AppDataContext;

  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    data: new Project(),
    isLoaded: null,
    isSubmitting: null
  };

  componentDidMount() {
    if (this.getProjectId()) {
      this.loadData();
    }
  }

  getApiUrl = () => {
    const {
      urls: { projects: baseUrl }
    } = this.context;
    const projectId = this.getProjectId();

    return projectId ? `${baseUrl}/${projectId}` : baseUrl;
  };

  getProjectId() {
    const {
      match: {
        params: { id: projectId }
      }
    } = this.props;
    return projectId;
  }

  loadData = () => {
    const { onLogout } = this.context;

    api
      .get(this.getApiUrl(), {
        params: { stories: 0 }
      })
      .catch(logoutOnAccessDenied(onLogout))
      .then(({ data: apiData }) => {
        this.setState({ data: toProject(apiData), loaded: true });
      })
      .catch(() => {
        this.setState({ data: new Project(), loaded: false });
      });
  };

  handleSubmit = evt => {
    evt.preventDefault();
    this.setState({ isSubmitting: true });

    const { onLogout } = this.context;
    const { history } = this.props;
    const { data } = this.state;

    let method = "post";
    let expectStatusCode = 201;

    if (this.getProjectId()) {
      method = "put";
      expectStatusCode = 202;
    }

    api
      .request(this.getApiUrl(), {
        data: {
          name: data.name,
          slug: data.slug || null,
          description: data.description || null
        },
        method,
        validateStatus: status => status === expectStatusCode
      })
      .catch(logoutOnAccessDenied(onLogout))
      .then(({ data: apiData }) => {
        history.push(`/app/projects/${apiData.id}`);
      })
      .catch(() => {
        // TODO: Proper error handling
        this.setState({ isSubmitting: false });
      });
  };

  render() {
    const { data, isSubmitting } = this.state;
    const projectId = this.getProjectId();

    let breadcrumbsProps;
    let cancelProps;
    let headerLabel;
    let headerProps;
    let submitLabel;

    if (projectId) {
      // Edit Project
      const projectUrl = `/app/projects/${projectId}`;
      let mobileContentLabel = "Back to project";

      cancelProps = { to: projectUrl };
      headerLabel = "Edit Project";
      headerProps = {
        button: "Archive",
        buttonClassName: "btn-outline-danger",
        buttonIcon: "times",
        buttonLink: `${projectUrl}/archive`
      };
      submitLabel = "Edit";

      if (data.name) {
        mobileContentLabel = `Back to ${data.name}`;
      }

      breadcrumbsProps = {
        label: "Edit Project",
        mobileContent: (
          <Link className="d-sm-none small" to={projectUrl}>
            {mobileContentLabel}
          </Link>
        )
      };
    } else {
      // Add Project
      breadcrumbsProps = {
        label: "New Project",
        mobileContent: (
          <Link className="d-sm-none small" to="/app/projects">
            Back to projects
          </Link>
        )
      };
      cancelProps = {
        to: "/app/projects"
      };
      headerLabel = "New Project";
      submitLabel = "Add";
    }

    return (
      <div className="container container-main">
        <ProjectBreadcrumbs data={data} isLink {...breadcrumbsProps} />

        <PageHeader isFormNext {...headerProps}>
          {headerLabel}
        </PageHeader>

        <form noValidate onSubmit={this.handleSubmit}>
          <div className="form-group row">
            <Label htmlFor="name" isRequired>
              Name
            </Label>
            <InputContainer>
              <input
                autoFocus={!projectId}
                className="form-control"
                id="name"
                onChange={onChangeDataFactory(this, "name")}
                required
                type="text"
                value={data.name || ""}
              />
            </InputContainer>
          </div>

          <div className="form-group row">
            <Label htmlFor="slug">Slug</Label>
            <InputContainer>
              <input
                className="form-control"
                id="slug"
                onChange={onChangeDataFactory(this, "slug")}
                type="text"
                value={data.slug || ""}
              />
            </InputContainer>
          </div>

          <div className="form-group row">
            <Label htmlFor="description">Description</Label>
            <InputContainer
              help={
                <Fragment>
                  <HelpText isInForm>
                    <a href="https://daringfireball.net/projects/markdown/">
                      Markdown
                    </a>{" "}
                    syntax supported.
                  </HelpText>
                  {data.description ? (
                    <Fragment>
                      <hr />
                      <ProjectDescription data={data} />
                      <hr />
                    </Fragment>
                  ) : null}
                </Fragment>
              }
            >
              <textarea
                className="form-control"
                id="description"
                onChange={onChangeDataFactory(this, "description")}
                rows="5"
                value={data.description || ""}
              />
            </InputContainer>
          </div>

          <ButtonsRow>
            <SubmitButton isDisabled={isSubmitting} onClick={this.handleSubmit}>
              {submitLabel}
            </SubmitButton>
            <CancelButton {...cancelProps} />
          </ButtonsRow>
        </form>
      </div>
    );
  }
}
