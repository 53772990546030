import classnames from "classnames";
import I from "immutable";
import PropTypes from "prop-types";
import qs from "qs";
import React from "react";
import { Link, withRouter } from "react-router-dom";

import { StopStoriesFiltersDataPropType } from "../propTypes";
import { getLocationQuery } from "../utils";

export const getProjectFilterLabel = (data, projects) => {
  if (!data.project) {
    return "All";
  }

  const selectedProject = projects.find(
    ({ id }) => id.toString() === data.project
  );
  return selectedProject ? selectedProject.name : null;
};

const StopStoriesProjectFilter = ({ data, location, projects }) => {
  if (projects.isEmpty()) {
    return null;
  }

  const { pathname } = location;
  const query = getLocationQuery(location);
  const isAll = !data.project;

  return (
    <div className="row py-2">
      <div className="col-12">
        <nav className="nav">
          <span className="nav-item nav-link disabled pr-0 py-0">
            Show stories by project:
          </span>

          <Link
            className={classnames("nav-item nav-link pr-0 py-0", {
              active: isAll,
              "font-weight-bold": isAll
            })}
            to={{
              pathname,
              search: `?${qs.stringify({ ...query, project: undefined })}`
            }}
          >
            All
          </Link>

          {projects
            .map(({ id, name }) => (
              <Link
                className={classnames("nav-item nav-link pr-0 py-0", {
                  active: id.toString() === data.project,
                  "font-weight-bold": id.toString() === data.project
                })}
                key={id}
                to={{
                  pathname,
                  search: `?${qs.stringify({
                    ...query,
                    project: id.toString()
                  })}`
                }}
              >
                {name}
              </Link>
            ))
            .toArray()}
        </nav>
      </div>
    </div>
  );
};

StopStoriesProjectFilter.propTypes = {
  data: StopStoriesFiltersDataPropType.isRequired,
  location: PropTypes.object.isRequired,
  projects: PropTypes.instanceOf(I.List).isRequired
};

export default withRouter(StopStoriesProjectFilter);
