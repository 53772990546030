/**
 * Filter by Story Link
 * --------------------
 *
 * Link to filter stop stories by given story.
 *
 */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import qs from "qs";
import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";

import { Story } from "../records";
import { getLocationQuery, isOwnProject } from "../utils";

const FilterByStoryLink = ({ data, location, location: { pathname } }) => {
  const query = getLocationQuery(location);
  const { id, project, slug } = data;

  const externalLink = !isOwnProject(project) ? (
    <small style={{ marginLeft: "5px" }}>
      <a
        href={data.url}
        rel="noopener noreferrer"
        target="_blank"
        title={`Show story in external tracker: ${project.tracker}`}
      >
        <FontAwesomeIcon icon="external-link-alt" />
      </a>
    </small>
  ) : null;

  return (
    <Fragment>
      <Link
        title={`Show time spent for ${slug}`}
        to={{
          pathname,
          search: `?${qs.stringify({
            ...query,
            project: project.id,
            story: id
          })}`
        }}
      >
        {slug}
      </Link>
      {externalLink}
    </Fragment>
  );
};

FilterByStoryLink.propTypes = {
  data: PropTypes.instanceOf(Story).isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(FilterByStoryLink);
