import React from "react";
import { Link } from "react-router-dom";

import Logo from "./Logo";

const LandingHeader = () => (
  <div className="container mt-5">
    <h1 className="h2">
      <Link className="text-dark text-no-underline" to="/">
        <Logo />
        <span className="ml-2">MoneyTracker</span>
      </Link>
    </h1>
    <h2 className="h3 text-muted">
      <small>Developer time tracker and more</small>
    </h2>
    <hr className="my-5" />
  </div>
);

export default LandingHeader;
