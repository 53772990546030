/**
 * Input Container
 * ---------------
 *
 * One and only way of rendering input/textareas inside of any form.
 *
 */

import PropTypes from "prop-types";
import React, { Component } from "react";

import { APIError } from "../records";

export default class InputContainer extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    error: PropTypes.instanceOf(APIError),
    help: PropTypes.node
  };

  static defaultProps = {
    error: null,
    help: null
  };

  renderError() {
    const { error } = this.props;
    if (!error) {
      return null;
    }
    return <div className="text-danger">{error.message}</div>;
  }

  render() {
    const { children, help } = this.props;
    return (
      <div className="col-12 col-lg-10 col-sm-9">
        {children}
        {this.renderError()}
        {help}
      </div>
    );
  }
}
