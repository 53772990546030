/**
 * Tracker Form
 * ============
 *
 * Allows user to track new Stories via MoneyTracker.
 *
 */

import I from "immutable";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import api, { logoutOnAccessDenied } from "../api";
import ButtonsRow from "../components/ButtonsRow";
import CancelButton from "../components/CancelButton";
import Empty from "../components/Empty";
import InputContainer from "../components/InputContainer";
import Label from "../components/Label";
import Loading from "../components/Loading";
import PageHeader from "../components/PageHeader";
import ServerError from "../components/ServerError";
import SubmitButton from "../components/SubmitButton";
import TrackerStoriesSelect from "../components/TrackerStoriesSelect";
import { onRefreshDataFactory } from "../components/utils";
import { KEY_CANCEL, KEY_REFRESH } from "../constants";
import { AppDataContext } from "../contexts";
import { toSnapshotStory } from "../records";

export default withRouter(
  class TrackerPage extends Component {
    static contextType = AppDataContext;

    static defaultState = {
      data: new I.List(),
      isSubmitting: false,
      selectedSnapshot: null,
      status: null
    };

    static propTypes = {
      history: PropTypes.object.isRequired
    };

    state = TrackerPage.defaultState;

    componentDidMount() {
      this.loadData();
      window.addEventListener("keydown", this.handleKeyDown, false);
    }

    componentWillUnmount() {
      this.isUnmounted = true;
      window.removeEventListener("keydown", this.handleKeyDown, false);
    }

    isUnmounted = false;

    loadData = () => {
      const {
        onLogout,
        urls: { trackerStories: url }
      } = this.context;
      const toList = data => new I.List(data.map(toSnapshotStory));

      api
        .get(url)
        .catch(logoutOnAccessDenied(onLogout))
        .then(({ data: apiData }) => {
          if (this.isUnmounted) {
            return;
          }

          const data = toList(apiData);
          this.setState({
            data,
            selectedSnapshot: data.first().snapshot,
            status: true
          });
        })
        .catch(() => {
          if (this.isUnmounted) {
            return;
          }
          this.setState({ data: new I.List(), status: false });
        });
    };

    handleChangeSnapshot = evt => {
      this.setState({ selectedSnapshot: evt.target.value });
    };

    handleKeyDown = evt => {
      const { history } = this.props;

      if (evt.key === KEY_CANCEL) {
        history.push("/app");
      }

      if (evt.code === KEY_REFRESH) {
        this.handleRefreshData();
      }
    };

    handleRefreshData = () => {
      return onRefreshDataFactory(this, TrackerPage.defaultState)();
    };

    handleTrackStory = evt => {
      evt.preventDefault();
      this.setState({ isSubmitting: true });

      const {
        urls: { tracker: url }
      } = this.context;
      const { history } = this.props;
      const { selectedSnapshot } = this.state;

      // First stop currently tracked Story if any
      api
        .delete(url, {
          params: { notify: 0 },
          validateStatus: status => status === 204
        })
        .then(() => {
          // After currently tracked Story stop, track new Story
          api
            .post(
              url,
              { snapshot: selectedSnapshot },
              {
                validateStatus: status => status === 201
              }
            )
            .then(() => {
              history.push("/app");
            })
            .catch(() => {
              // TODO: Setup proper error handling
              this.setState({ isSubmitting: false });
            });
        })
        .catch(() => {
          // TODO: Setup proper error handling
          this.setState({ isSubmitting: false });
        });
    };

    render() {
      const { data, isSubmitting, status } = this.state;

      let content;
      let isDisabled = true;

      if (status === null) {
        content = <Loading isInForm />;
      } else if (status === false) {
        content = (
          <ServerError isInForm refreshHandler={this.handleRefreshData}>
            Stories available for tracking
          </ServerError>
        );
      } else if (data.count()) {
        content = (
          <TrackerStoriesSelect
            onChange={this.handleChangeSnapshot}
            onRefreshStories={this.handleRefreshData}
            stories={data}
          />
        );
        isDisabled = false;
      } else {
        content = (
          <Empty isInForm>
            No active Stories found across your trackers. Please make sure that
            Story started in your tracker or added and active in MoneyTracker.
          </Empty>
        );
      }

      // After submit started disable submit button
      if (isSubmitting) {
        isDisabled = true;
      }

      return (
        <div className="container container-main">
          <PageHeader isFormNext>Track New Story</PageHeader>
          <form noValidate onSubmit={this.handleTrackStory}>
            <fieldset>
              <div className="form-group row">
                <Label htmlFor="tracker-stories" isRequired>
                  Select Story
                </Label>
                <InputContainer>{content}</InputContainer>
              </div>
              <ButtonsRow>
                <SubmitButton
                  isDisabled={isDisabled}
                  onClick={this.handleTrackStory}
                >
                  Start Tracking
                </SubmitButton>
                <CancelButton to="/app" />
              </ButtonsRow>
            </fieldset>
          </form>
        </div>
      );
    }
  }
);
