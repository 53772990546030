import React from "react";

const Footer = () => (
  <footer className="footer footer-main">
    <div className="container">
      <div className="text-muted">
        MoneyTracker. Developer time tracker and more
        <br />
        <span className="mr-2">
          <a className="text-info" href="https://igordavydenko.com/">
            Igor Davydenko
          </a>{" "}
          project.
        </span>
        <br className="d-block d-sm-none" />
        <span>2014 - 2020</span>
      </div>
    </div>
  </footer>
);

export default Footer;
