/**
 * Update timezone modal
 * =====================
 *
 * If guessed timezone differs to default timezone - show modal to update
 * timezone.
 *
 */

import PropTypes from "prop-types";
import React, { Component } from "react";

import api, { logoutOnAccessDenied } from "../api";
import { TRUE_STR } from "../constants";
import { AppDataContext } from "../contexts";
import { toUser } from "../records";

const getStorageItemName = value => `updateTimezone:${value}`;

export const getStorageItem = value =>
  sessionStorage &&
  sessionStorage.getItem(getStorageItemName(value)) === TRUE_STR;

export const removeStorageItem = value => {
  if (sessionStorage) {
    sessionStorage.removeItem(getStorageItemName(value));
  }
};

export const setStorageItem = value => {
  if (sessionStorage) {
    sessionStorage.setItem(getStorageItemName(value), TRUE_STR);
  }
};

export default class UpdateTimezone extends Component {
  static contextType = AppDataContext;

  static propTypes = {
    newTimezone: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    const { newTimezone } = props;
    this.state = {
      isHidden: getStorageItem(newTimezone),
      newTimezone
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { newTimezone } = nextProps;

    if (newTimezone !== prevState.newTimezone) {
      return {
        ...prevState,
        isHidden: getStorageItem(newTimezone),
        newTimezone
      };
    }

    return prevState;
  }

  handleCancel = evt => {
    evt.preventDefault();

    this.setState(
      {
        isHidden: true
      },
      () => {
        const { newTimezone } = this.state;
        setStorageItem(newTimezone);
      }
    );
  };

  handleUpdate = evt => {
    evt.preventDefault();

    const {
      onLogout,
      onUpdateUser,
      urls: { timezone: url }
    } = this.context;
    const { newTimezone } = this.state;

    api
      .put(
        url,
        {
          timezone: newTimezone
        },
        {
          validateStatus: status => status === 202
        }
      )
      .catch(logoutOnAccessDenied(onLogout))
      .then(({ data: apiData }) => {
        removeStorageItem(newTimezone);
        onUpdateUser(toUser(apiData));
      });
  };

  render() {
    const {
      user: { timezone: defaultTimezone }
    } = this.context;
    const { isHidden, newTimezone } = this.state;

    if (isHidden) {
      return null;
    }

    return (
      <div className="alert alert-dismissable alert-secondary" role="alert">
        <button
          aria-label="Close"
          className="close"
          onClick={this.handleCancel}
          type="button"
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <h4 className="alert-heading">Update timezone?</h4>

        <div className="mb-2 mt-2">
          We detected that your current timezone <b>{newTimezone}</b> differs
          from previously set timezone <i>{defaultTimezone}</i>. Do you want to
          update your profile to use new timezone?
        </div>

        <div>
          <button
            className="btn btn-secondary col-12 col-sm-4 col-lg-2"
            onClick={this.handleUpdate}
            type="button"
          >
            Update
          </button>
          <button
            aria-label="Close"
            className="btn btn-link pl-0 pl-sm-3"
            onClick={this.handleCancel}
            type="button"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}
