import classnames from "classnames";
import I from "immutable";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import api from "../api";
import ButtonsRow from "../components/ButtonsRow";
import CancelButton from "../components/CancelButton";
import HelpText from "../components/HelpText";
import InputContainer from "../components/InputContainer";
import Label from "../components/Label";
import PageHeader from "../components/PageHeader";
import SubmitButton from "../components/SubmitButton";
import { onChangeDataFactory } from "../components/utils";
import { AppDataContext } from "../contexts";
import { toAPIError } from "../records";
import { getLoginUrl } from "../utils";

const STORAGE_KEY = "moneytracker:email-login-page";

const Data = new I.Record({
  email: "",
  code: ""
});

export default withRouter(
  class EmailLoginPage extends Component {
    static contextType = AppDataContext;

    static propTypes = {
      history: PropTypes.object.isRequired
    };

    constructor(props) {
      super(props);

      const defaultData = new Data();
      const storage = this.getStorage();

      this.state = {
        data: defaultData.merge(storage.data),
        error: null,
        isRequested: storage.isRequested || false,
        isSubmitted: false,
        timestamp: storage.timestamp || null
      };
    }

    componentDidMount() {
      window.addEventListener("keydown", this.handleEscape, false);
    }

    componentWillUnmount() {
      this.isUnmounted = true;
      window.removeEventListener("keydown", this.handleEscape, false);
    }

    isUnmounted = false;

    handleCancel = evt => {
      evt.preventDefault();
      this.cancel();
    };

    handleEscape = evt => {
      if (evt.key === "Escape") {
        this.cancel();
      }
    };

    handleSubmit = evt => {
      evt.preventDefault();

      const { isRequested } = this.state;
      if (isRequested) {
        this.validateToken();
      } else {
        this.requestToken();
      }
    };

    cancel = () => {
      const { history } = this.props;
      sessionStorage.removeItem(STORAGE_KEY);
      history.push("/");
    };

    getStorage() {
      const data = sessionStorage.getItem(STORAGE_KEY);
      return data && data.length ? JSON.parse(data) : {};
    }

    requestToken = () => {
      this.setState({ isSubmitted: true });

      const {
        urls: {
          login: { otpRequest: url }
        }
      } = this.context;
      const { data } = this.state;

      const defaultState = {
        data,
        error: null,
        isSubmitted: false
      };

      api
        .post(url, { email: data.email })
        .then(({ data: apiData }) => {
          if (this.isUnmounted) {
            return;
          }
          const state = {
            ...defaultState,
            isRequested: true,
            timestamp: apiData.timestamp
          };
          this.setState(state, () => this.setStorage(state));
        })
        .catch(err => {
          if (this.isUnmounted) {
            return;
          }

          const state = {
            ...defaultState,
            error: toAPIError(err, "email")
          };
          this.setState(state, () => this.setStorage(state));
        });
    };

    setStorage(state) {
      sessionStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    }

    validateToken = () => {
      this.setState({ isSubmitted: true });

      const {
        urls: {
          login: { otpValidate: url }
        }
      } = this.context;
      const { data, timestamp } = this.state;

      api
        .post(url, {
          email: data.email,
          otp: data.code,
          timestamp: timestamp.toString()
        })
        .then(({ data: apiData }) => {
          if (this.isUnmounted) {
            return;
          }
          sessionStorage.removeItem(STORAGE_KEY);
          window.location = getLoginUrl(apiData.url);
        })
        .catch(err => {
          if (this.isUnmounted) {
            return;
          }
          this.setState({ error: toAPIError(err, "otp"), isSubmitted: false });
        });
    };

    render() {
      const { data, error, isRequested, isSubmitted } = this.state;

      let codeRow;
      if (isRequested) {
        codeRow = (
          <div className="form-group row">
            <Label htmlFor="code" isRequired>
              Login Code
            </Label>
            <InputContainer
              error={error}
              help={
                <HelpText isInForm>
                  Your login code is 6-digit number which we sent to your email
                  moments ago.
                </HelpText>
              }
            >
              <input
                autoComplete="off"
                autoFocus
                className={classnames("form-control", {
                  "is-invalid": Boolean(error)
                })}
                id="code"
                maximum="999999"
                min="0"
                onChange={onChangeDataFactory(this, "code")}
                required
                type="number"
                value={data.code}
              />
            </InputContainer>
          </div>
        );
      }

      return (
        <div className="container mt-5">
          <PageHeader isFormNext>Login via Email</PageHeader>
          <form noValidate onSubmit={this.handleSubmit}>
            <fieldset>
              <div className="form-group row">
                <Label htmlFor="email" isRequired>
                  Your Email
                </Label>
                <InputContainer
                  error={!isRequested ? error : null}
                  help={
                    !isRequested && (
                      <HelpText isInForm>
                        Provide your valid email address.
                        <br />
                        <small>
                          Your login code would be sent there and been valid for
                          next 15 minutes after submission.
                        </small>
                      </HelpText>
                    )
                  }
                >
                  <input
                    autoFocus={!isRequested}
                    className={classnames("form-control", {
                      "is-invalid": Boolean(error)
                    })}
                    disabled={isRequested}
                    id="email"
                    onChange={onChangeDataFactory(this, "email")}
                    placeholder="you@gmail.com"
                    required
                    type="email"
                    value={data.email || ""}
                  />
                </InputContainer>
              </div>

              {codeRow}

              <ButtonsRow>
                <SubmitButton
                  isDisabled={isSubmitted}
                  onClick={this.handleSubmit}
                >
                  {isRequested ? "Login" : "Submit"}
                </SubmitButton>
                <CancelButton onClick={this.handleCancel} />
              </ButtonsRow>
            </fieldset>
          </form>
        </div>
      );
    }
  }
);
