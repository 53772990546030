/**
 * Targets Page
 * ============
 *
 * Setup and control targets.
 *
 */

import React from "react";

import PageHeader from "../components/PageHeader";
import TargetsTable from "../components/TargetsTable";

const TargetsPage = () => (
  <div className="container container-main">
    <PageHeader
      button="New Target"
      buttonIcon="plus"
      buttonLink="/app/targets/add"
    >
      Targets
    </PageHeader>

    <TargetsTable />

    <p className="mt-3 mt-md-0 text-muted">
      Specify targets to allow MoneyTracker controls your working hours and
      notify, when you under or overworked.
    </p>

    <p className="text-muted">
      For example, you might add the target for working 30 hrs a week, and if
      you worked 20 hrs previous week at Monday you will be notified by email on
      underachievment with detailed report. Then if you worked 30 hrs or more
      and select to receive notification on success, you will also receive a
      detailed report with your activity.
    </p>

    <p className="text-muted">
      Finally, targets may be attached to the project or be the global one. And
      the target can be attached to one of period: day, week, month, quarter, or
      year.
    </p>
  </div>
);

export default TargetsPage;
