/**
 * Submit Button
 * =============
 *
 * Standard Submit button displayed inside col container.
 *
 */

import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const SubmitButton = ({
  children,
  className,
  colClassName,
  hasOffset,
  isDisabled,
  onClick,
  order,
  to
}) => {
  let button;

  if (onClick === null && to !== null) {
    button = (
      <Link className={className} to={to}>
        {children}
      </Link>
    );
  } else {
    button = (
      <button
        className={className}
        disabled={isDisabled}
        onClick={onClick}
        type="submit"
      >
        {children}
      </button>
    );
  }

  return (
    <div
      className={classnames(colClassName, {
        [`order-${order}`]: order !== null,
        "offset-sm-3 offset-lg-2": hasOffset
      })}
    >
      {button}
    </div>
  );
};

SubmitButton.defaultProps = {
  children: "Submit",
  className: "btn btn-block btn-success",
  colClassName: "col-12 col-sm-4 col-lg-3",
  hasOffset: true,
  isDisabled: false,
  onClick: null,
  order: null,
  to: null
};
SubmitButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  colClassName: PropTypes.string,
  hasOffset: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  order: PropTypes.number,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default SubmitButton;
