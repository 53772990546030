import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Logo = () => (
  <span className="fa-layers fa-fw">
    <FontAwesomeIcon color="#e0e0e0" icon="database" />
    <FontAwesomeIcon icon="dollar-sign" size="xs" />
  </span>
);

export default Logo;
