import { library } from "@fortawesome/fontawesome-svg-core";
import { faGithub } from "@fortawesome/free-brands-svg-icons/faGithub";
import { faGitlab } from "@fortawesome/free-brands-svg-icons/faGitlab";
import { faGoogle } from "@fortawesome/free-brands-svg-icons/faGoogle";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons/faCaretUp";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { faDatabase } from "@fortawesome/free-solid-svg-icons/faDatabase";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons/faDollarSign";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import { faFilter } from "@fortawesome/free-solid-svg-icons/faFilter";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";

library.add(
  // Brand icons
  faGithub,
  faGitlab,
  faGoogle,
  // Solid icons
  faCaretDown,
  faCaretUp,
  faCheck,
  faCircleNotch,
  faDatabase,
  faDollarSign,
  faDownload,
  faEdit,
  faEnvelope,
  faExternalLinkAlt,
  faFilter,
  faPlus,
  faTimes
);
