import React from "react";
import { Link } from "react-router-dom";

import PageHeader from "../components/PageHeader";
import { KEY_CANCEL, KEY_REFRESH, KEY_START, KEY_STOP } from "../constants";

const HelpPage = () => (
  <div className="container container-main">
    <PageHeader>MoneyTracker Help</PageHeader>
    <p>This page contains gotchas on how to use MoneyTracker on daily basis.</p>
    <p>
      Feel free to ask us more by sending an email to{" "}
      <a href="mailto:iam@igordavydenko.com">iam@igordavydenko.com</a> with
      subject <em>MoneyTracker Help</em>.
    </p>

    <h2 id="keyboard-shortcuts" className="mt-5">
      Keyboard Shortcuts
    </h2>
    <p>
      There are some keyboard shortcuts that simplify using MoneyTracker web
      app.
    </p>

    <h3 id="keyboard-shortcuts-main-page">
      <Link to="/app">Main Page</Link>
    </h3>
    <p>On main page 3 keyboard shortcuts enabled:</p>
    <ul>
      <li>
        <code>{KEY_START}</code> to go to{" "}
        <Link to="/app/tracker">Tracker Page</Link> and start tracking new story
      </li>
      <li>
        <code>{KEY_STOP}</code> to stop tracking story if any
      </li>
      <li>
        <code>{KEY_REFRESH}</code> to refresh current tracking story data
      </li>
    </ul>

    <h3 id="keyboard-shortcuts-tracker-page">
      <Link to="/app/tracker">Tracker Page</Link>
    </h3>
    <p>On tracker page next keyboard shortcuts enabled:</p>
    <ul>
      <li>
        <code>{KEY_REFRESH}</code> to refresh list of available stories
      </li>
      <li>
        <code>{KEY_CANCEL}</code> to cancel and return to main page
      </li>
    </ul>

    <p>
      <b>Note:</b> You don&apos;t need to enable English keyboard layout in
      order to use keyboard shortcuts. For example, <code>KeyS</code> means that
      MoneyTracker web app supports key presses to <code>S</code>, while English
      keyboard layout enabled, and in same time to <code>Ы</code>, when Russian
      keyboard layout enabled.
    </p>
  </div>
);

export default HelpPage;
