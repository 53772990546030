/**
 * Page Header
 * ===========
 *
 * Component to show bootstrap's Page Header element.
 *
 */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const PageHeader = ({
  button,
  buttonClassName,
  buttonIcon,
  buttonLink,
  children,
  isFormNext
}) => {
  const header = (
    <h1 className={classnames("mt-4", { "mb-4": isFormNext })}>{children}</h1>
  );
  return !button ? (
    header
  ) : (
    <div className="row">
      <div className="col-12 col-lg-9 col-md-8 col-xl-10">{header}</div>
      <div className="col-12 col-lg-3 col-md-4 col-xl-2">
        <Link
          className={classnames(
            "btn",
            "btn-block",
            "btn-outline-secondary",
            "mt-md-4",
            buttonClassName
          )}
          to={buttonLink}
        >
          {buttonIcon ? (
            <FontAwesomeIcon className="mr-2" icon={buttonIcon} />
          ) : null}
          {button}
        </Link>
      </div>
    </div>
  );
};

PageHeader.defaultProps = {
  button: null,
  buttonClassName: null,
  buttonIcon: null,
  buttonLink: null,
  isFormNext: false
};
PageHeader.propTypes = {
  button: PropTypes.node,
  buttonClassName: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonLink: PropTypes.string,
  children: PropTypes.node.isRequired,
  isFormNext: PropTypes.bool
};

export default PageHeader;
