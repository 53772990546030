/**
 * Edit profile form
 * =================
 *
 * Gives user ability to edit display name, timezone, and integrations with
 * 3rd party services.
 *
 */

import PropTypes from "prop-types";
import React, { Component } from "react";

import api, { logoutOnAccessDenied } from "../api";
import ButtonsRow from "../components/ButtonsRow";
import CancelButton from "../components/CancelButton";
import HelpText from "../components/HelpText";
import InputContatiner from "../components/InputContainer";
import IntegrationStatus from "../components/IntegrationStatus";
import Label from "../components/Label";
import PageHeader from "../components/PageHeader";
import SubmitButton from "../components/SubmitButton";
import TimezonesList from "../components/TimezonesList";
import { removeStorageItem } from "../components/UpdateTimezone";
import { onChangeDataFactory } from "../components/utils";
import { AppDataContext } from "../contexts";
import { toUser, User } from "../records";

export default class ProfileFormPage extends Component {
  static contextType = AppDataContext;

  static propTypes = {
    user: PropTypes.instanceOf(User).isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      data: props.user,
      defaultData: props.user,
      isLoaded: null,
      isSubmitting: null,
      isUpdated: null,
      jiraProjects: 0,
      timezones: []
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.user !== state.data && props.user !== state.defaultData) {
      return {
        ...state,
        data: props.user,
        defaultData: props.user
      };
    }
    return state;
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const {
      onLogout,
      urls: { profile: url }
    } = this.context;

    api
      .options(url)
      .catch(logoutOnAccessDenied(onLogout))
      .then(({ data: apiData }) => {
        this.setState({
          ...apiData,
          isLoaded: true
        });
      });
  };

  handleSubmit = evt => {
    evt.preventDefault();
    this.setState({ isSubmitting: true });

    const {
      onLogout,
      onUpdateUser,
      urls: { profile: url }
    } = this.context;
    const { data } = this.state;

    api
      .put(url, {
        displayName: data.displayName || null,
        timezone: data.timezone,
        jiraUrl: data.jiraUrl || null,
        jiraUsername: data.jiraUsername || null,
        jiraPassword: data.jiraPassword || null,
        jiraIncludeProjects: data.jiraIncludeProjects,
        jiraExcludeProjects: data.jiraExcludeProjects || null
      })
      .catch(logoutOnAccessDenied(onLogout))
      .then(({ data: apiData }) => {
        const data = toUser(apiData);
        onUpdateUser(data);
        removeStorageItem(data.timezone);
        this.setState({ data, isSubmitting: false });
      });
  };

  render() {
    const {
      urls: { refreshIntegration: refreshIntegrationUrl }
    } = this.context;
    const {
      data,
      jiraProjects,
      isLoaded,
      isSubmitting,
      timezones
    } = this.state;

    let jiraStatus;
    let timezonesList;

    if (isLoaded) {
      if (timezones.length) {
        timezonesList = <TimezonesList timezones={timezones} />;
      }

      if (data.jiraUrl) {
        jiraStatus = (
          <IntegrationStatus
            defaultCounter={jiraProjects}
            tracker="jira"
            url={refreshIntegrationUrl}
          >
            JIRA
          </IntegrationStatus>
        );
      }
    }

    return (
      <div className="container container-main">
        <PageHeader>Settings</PageHeader>

        <form noValidate onSubmit={this.handleSubmit}>
          <fieldset>
            <div className="form-group row">
              <Label htmlFor="email" isRequired>
                Email
              </Label>
              <InputContatiner>
                <p className="form-control-plaintext" id="email">
                  {data.email}
                </p>
              </InputContatiner>
            </div>

            <div className="form-group row">
              <Label htmlFor="displayName">Display Name</Label>
              <InputContatiner>
                <input
                  autoFocus
                  className="form-control"
                  id="displayName"
                  onChange={onChangeDataFactory(this, "displayName")}
                  required
                  type="text"
                  value={data.displayName}
                />
              </InputContatiner>
            </div>

            <div className="form-group row">
              <Label htmlFor="timezone" isRequired>
                Timezone
              </Label>
              <InputContatiner
                help={
                  <HelpText>
                    Timezone to be used for displaying datetimes around
                    MoneyTracker app.
                  </HelpText>
                }
              >
                {timezonesList}
                <input
                  className="form-control"
                  id="timezone"
                  list="timezones"
                  onChange={onChangeDataFactory(this, "timezone")}
                  required
                  type="text"
                  value={data.timezone}
                />
              </InputContatiner>
            </div>
          </fieldset>

          <fieldset>
            <legend className="font-weight-bold">JIRA Integration</legend>

            <div className="form-group row">
              <Label htmlFor="jiraUrl">JIRA URL</Label>
              <InputContatiner
                help={
                  <HelpText>
                    URL with JIRA 5+ installation.
                    <br />
                    To enable JIRA support please provide valid JIRA URL,
                    username, and password to use for authentication.
                    <br />
                    To disable JIRA support blank at least one of those fields.
                  </HelpText>
                }
              >
                <input
                  className="form-control"
                  id="jiraUrl"
                  onChange={onChangeDataFactory(this, "jiraUrl")}
                  placeholder="https://<company>.atlassian.net/"
                  type="url"
                  value={data.jiraUrl || ""}
                />
                {jiraStatus}{" "}
              </InputContatiner>
            </div>

            <div className="form-group row">
              <Label htmlFor="jiraUsername">JIRA Username</Label>
              <InputContatiner>
                <input
                  className="form-control"
                  id="jiraUsername"
                  onChange={onChangeDataFactory(this, "jiraUsername")}
                  type="text"
                  value={data.jiraUsername || ""}
                />
              </InputContatiner>
            </div>

            <div className="form-group row">
              <Label htmlFor="jiraPassword">JIRA Password</Label>
              <InputContatiner
                help={
                  <HelpText>
                    <b>Note:</b> Your password would be stored as plain text.
                    For your safety do not use your common password to connect
                    to JIRA.
                  </HelpText>
                }
              >
                <input
                  className="form-control"
                  id="jiraPassword"
                  onChange={onChangeDataFactory(this, "jiraPassword")}
                  type="password"
                  value={data.jiraPassword || ""}
                />
              </InputContatiner>
            </div>

            <div className="form-group row">
              <Label htmlFor="jiraIncludeProjects">Include</Label>
              <InputContatiner>
                <input
                  className="form-control"
                  id="jiraIncludeProjects"
                  onChange={onChangeDataFactory(this, "jiraIncludeProjects")}
                  type="text"
                  value={data.jiraIncludeProjects}
                />
              </InputContatiner>
            </div>

            <div className="form-group row">
              <Label htmlFor="jiraExcludeProjects">Exclude Projects</Label>
              <InputContatiner
                help={
                  <HelpText>
                    You are able to limit number of projects to sync from JIRA.
                    To do that include only necessary projects by its slug, or
                    exclude unnecessray projects by its slug. By default: all
                    projects will be included and none be excluded.
                  </HelpText>
                }
              >
                <input
                  className="form-control"
                  id="jiraExcludeProjects"
                  onChange={onChangeDataFactory(this, "jiraExcludeProjects")}
                  type="text"
                  value={data.jiraExcludeProjects || ""}
                />
              </InputContatiner>
            </div>
          </fieldset>

          <ButtonsRow>
            <SubmitButton
              isDisabled={isSubmitting}
              onClick={this.handleSubmit}
            />
            <CancelButton to="/app">To Dashboard</CancelButton>
          </ButtonsRow>
        </form>
      </div>
    );
  }
}
