/**
 * Project Stories
 * ===============
 *
 * Render Project Stories list and manipulate with stories.
 *
 */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import I from "immutable";
import PropTypes from "prop-types";
import qs from "qs";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import api, { logoutOnAccessDenied } from "../api";
import { AppDataContext } from "../contexts";
import { ShortStory, toShortStory } from "../records";
import { getLocationQuery } from "../utils";

import HelpText from "./HelpText";
import { onChangeDataFactory } from "./utils";

export default withRouter(
  class ProjectStories extends Component {
    static contextType = AppDataContext;

    static defaultProps = {
      className: null,
      isAll: null
    };

    static propTypes = {
      className: PropTypes.string,
      defaultStories: PropTypes.instanceOf(I.List).isRequired,
      isAll: PropTypes.bool,
      location: PropTypes.object.isRequired,
      projectId: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired
    };

    constructor(props) {
      super(props);

      this.state = {
        data: new ShortStory(),
        isFormOpened: false,
        isSubmitting: false,
        stories: new I.OrderedMap(
          props.defaultStories.map(story => [story.id, story])
        ),
        toggleQueue: new I.Set()
      };
    }

    componentDidMount() {
      window.addEventListener("keydown", this.handleEscape, false);
    }

    componentWillUnmount() {
      window.removeEventListener("keydown", this.handleEscape, false);
    }

    handleEditFactory = storyId => {
      return evt => {
        evt.preventDefault();
        this.setState(state => ({
          data: state.stories.get(storyId),
          isFormOpened: true
        }));
      };
    };

    handleEscape = evt => {
      if (evt.key === "Escape") {
        this.setState({ data: new ShortStory(), isFormOpened: false });
      }
    };

    handleHideForm = evt => {
      evt.preventDefault();
      this.setState({ data: new ShortStory(), isFormOpened: false });
    };

    handleShowForm = evt => {
      evt.preventDefault();
      this.setState({ isFormOpened: true });
    };

    handleSubmit = evt => {
      evt.preventDefault();
      this.setState({ isSubmitting: true });

      const { onLogout } = this.context;
      const { data } = this.state;

      let method = "post";
      let expectStatusCode = 201;

      if (data.id) {
        method = "put";
        expectStatusCode = 202;
      }

      api
        .request(this.getApiUrl(), {
          data: {
            name: data.name,
            description: data.description || null,
            labels: data.labels || null
          },
          method,
          validateStatus: status => status === expectStatusCode
        })
        .catch(logoutOnAccessDenied(onLogout))
        .then(({ data: apiData }) => {
          this.setState(state => ({
            data: new ShortStory(),
            isFormOpened: false,
            isSubmitting: false,
            stories: state.stories.set(apiData.id, toShortStory(apiData))
          }));
        })
        .catch(() => {
          // TODO: Proper error handling
          this.setState({
            data: new ShortStory(),
            isFormOpened: false,
            isSubmitting: false
          });
        });
    };

    handleToggleFactory = storyId => {
      return evt => {
        evt.preventDefault();

        const { onLogout } = this.context;
        const { stories, toggleQueue } = this.state;
        if (toggleQueue.has(storyId)) {
          return;
        }

        this.setState({ toggleQueue: toggleQueue.add(storyId) });

        const story = stories.get(storyId);
        const newValue = !story.isActive;

        api
          .patch(
            this.getApiUrl(storyId),
            {
              isActive: Number(newValue).toString()
            },
            {
              validateStatus: status => status === 202
            }
          )
          .catch(logoutOnAccessDenied(onLogout))
          .then(() => {
            this.setState(({ stories, toggleQueue }) => ({
              stories: stories.updateIn([storyId, "isActive"], () => newValue),
              toggleQueue: toggleQueue.delete(storyId)
            }));
          })
          .catch(() => {
            this.setState(({ toggleQueue }) => ({
              toggleQueue: toggleQueue.delete(storyId)
            }));
          });
      };
    };

    getApiUrl = storyId => {
      const { data } = this.state;
      const { url } = this.props;
      return storyId || data.id ? `${url}/${storyId || data.id}` : url;
    };

    renderForm = () => {
      const { data, isFormOpened, isSubmitting } = this.state;
      if (!isFormOpened) {
        return null;
      }

      return (
        <form noValidate onSubmit={this.handleSubmit}>
          <fieldset>
            <legend>{data.id ? `Edit Story ${data.slug}` : "New Story"}</legend>

            <div className="row no-gutters">
              <div className="col-lg-3 col-12 mb-1 mb-lg-0 pr-lg-2">
                <label className="sr-only" htmlFor="story-name">
                  Name
                </label>
                <input
                  autoComplete="off"
                  autoFocus
                  className="form-control form-control-sm"
                  id="story-name"
                  onChange={onChangeDataFactory(this, "name")}
                  placeholder="Name"
                  required
                  type="text"
                  value={data.name || ""}
                />
              </div>

              <div className="col-lg-5 col-12 mb-1 mb-lg-0 pr-lg-2">
                <label className="sr-only" htmlFor="story-description">
                  Description
                </label>
                <input
                  autoComplete="off"
                  className="form-control form-control-sm"
                  id="story-description"
                  onChange={onChangeDataFactory(this, "description")}
                  placeholder="Description"
                  type="text"
                  value={data.description || ""}
                />
              </div>

              <div className="col-lg-2 col-12 mb-1 mb-lg-0 pr-lg-2">
                <label className="sr-only" htmlFor="story-labels">
                  Labels
                </label>
                <input
                  autoComplete="off"
                  className="form-control form-control-sm"
                  id="story_labels"
                  onChange={onChangeDataFactory(this, "labels")}
                  placeholder="Labels"
                  type="text"
                  value={data.labels || ""}
                />
              </div>

              <div className="col-lg-1 col-12 mb-1 mb-lg-0 pr-lg-2">
                <button
                  className="btn btn-block btn-success btn-sm"
                  disabled={isSubmitting}
                  onClick={this.handleSubmit}
                  type="submit"
                >
                  <FontAwesomeIcon icon="check" />
                  <span className="ml-2 d-inline d-lg-none">
                    {data.id ? "Edit" : "New"} Story
                  </span>
                </button>
              </div>

              <div className="col-lg-1 col-12">
                <button
                  className="btn btn-block btn-danger btn-sm"
                  onClick={this.handleHideForm}
                  type="button"
                >
                  <FontAwesomeIcon icon="times" />
                  <span className="ml-2 d-inline d-lg-none">Cancel</span>
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      );
    };

    renderStoryItem = story => {
      const { toggleQueue } = this.state;
      const { projectId } = this.props;

      const { id: storyId, isActive } = story;
      const classNames = classnames({ "text-muted": !isActive });
      const editLink = (
        <a href="#" onClick={this.handleEditFactory(storyId)}>
          Edit
        </a>
      );
      const key = `project-${projectId}-story-${storyId}`;

      let toggleLink;
      if (toggleQueue.has(storyId)) {
        toggleLink = <HelpText>Processing&hellip;</HelpText>;
      } else {
        toggleLink = (
          <a
            className="text-danger"
            href="#"
            onClick={this.handleToggleFactory(storyId)}
          >
            {isActive ? "Deactivate" : "Activate"}
          </a>
        );
      }

      return (
        <li className={classNames} key={key}>
          <strong>[{story.slug}]</strong> {story.name}
          <small>
            {" "}
            &mdash; {editLink} &middot; {toggleLink}
          </small>
        </li>
      );
    };

    render() {
      const {
        className,
        isAll,
        location,
        location: { pathname }
      } = this.props;
      const { isFormOpened, stories } = this.state;

      const newClassNames = "btn btn-success btn-sm col-6 col-lg-2 col-sm-3";
      const newStoryItem = !isFormOpened ? (
        <li className="pt-2">
          <button
            className={newClassNames}
            onClick={this.handleShowForm}
            type="button"
          >
            <FontAwesomeIcon className="mr-2" icon="plus" /> New Story
          </button>
        </li>
      ) : null;
      const query = getLocationQuery(location);

      let content;
      if (!stories.isEmpty()) {
        content = stories
          .map(this.renderStoryItem)
          .valueSeq()
          .toArray();
      } else {
        content = (
          <li className="text-muted">
            Project doesn’t contain Stories. Add at least one Story to be able
            track it later.
          </li>
        );
      }

      return (
        <div className={className}>
          <h3 className="sub-header">
            Stories
            <span
              style={{
                display: "block",
                fontSize: "0.75rem",
                fontWeight: "normal",
                lineHeight: "0.95rem"
              }}
            >
              <Link
                to={{
                  pathname,
                  search: `?${qs.stringify({
                    ...query,
                    all: isAll ? undefined : 1
                  })}`
                }}
              >
                Show {isAll ? "only active" : "all"} stories
              </Link>
            </span>
          </h3>

          <ul className="list-unstyled">
            {content}
            {newStoryItem}
          </ul>

          {this.renderForm()}
        </div>
      );
    }
  }
);
