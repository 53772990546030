/**
 * API layer
 * --------
 *
 * Communicating with API via axios library.
 *
 */

import axios from "axios";
import qs from "qs";
import uuidv4 from "uuid/v4";

import { camelCase, snakeCase, transformKeys } from "./utils";

const api = axios.create({
  baseURL: process.env.API_URL || "/api/",
  headers: {
    "X-Client-UID": uuidv4(),
    "X-Requested-With": "XMLHttpRequest"
  },
  paramsSerializer: params => qs.stringify(transformKeys(params, snakeCase)),
  responseType: "json",
  timeout: 30000,
  transformRequest: [data => transformKeys(data, snakeCase)].concat(
    axios.defaults.transformRequest
  ),
  transformResponse: [data => transformKeys(data, camelCase)],
  withCredentials: true
});

export const logoutOnAccessDenied = onLogout => err => {
  if (err.response && err.response.status === 403) {
    onLogout();
  }
};

export default api;
