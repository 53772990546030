/**
 * Utilities
 * =========
 *
 * Various utilities to use in MoneyTracker UI.
 *
 */

import isObject from "lodash.isobject";
import moment from "moment-timezone";
import qs from "qs";
import URL from "url-parse";

import { DATE_TIME_MASK } from "./constants";

export const camelCase = value =>
  value.replace(/_([a-z])/g, item => item[1].toUpperCase());

export const getLocationQuery = location =>
  qs.parse(location.search ? location.search.slice(1) : location.search);

export const getLoginUrl = loginUrl => {
  const url = new URL(loginUrl);

  const nextUrl = new URL(document.baseURI);
  nextUrl.set("pathname", "/app");

  url.set("query", qs.stringify({ next: nextUrl.toString() }));
  return url.toString();
};

export const guessNewTimezone = timezone => {
  if (!timezone) {
    return null;
  }

  const otherTimezone = moment.tz.guess();
  if (!otherTimezone) {
    return null;
  }

  const zone = moment.tz.zone(timezone);
  const otherZone = moment.tz.zone(otherTimezone);
  const timeStamp = moment().format("X");

  return zone.utcOffset(timeStamp) !== otherZone.utcOffset(timeStamp)
    ? otherTimezone
    : null;
};

export const humanDelta = (story, timezone) => {
  const formats = ["H:mm", "H:mm"];

  let startedAt = moment(story.startedAt, DATE_TIME_MASK);
  let stopAt = story.stopAt ? moment(story.stopAt, DATE_TIME_MASK) : moment();

  if (startedAt.date() !== stopAt.date()) {
    formats[0] = "ddd, D MMM, H:mm";
  }

  if (timezone) {
    startedAt = startedAt.tz(timezone);
    stopAt = stopAt.tz(timezone);
  }

  if (story.stopAt) {
    return `${startedAt.format(formats[0])} - ${stopAt.format(formats[1])}`;
  }
  return `Started at ${startedAt.format(formats[0])}`;
};

export const isEditableStory = ({ lockedAt }) => {
  if (!lockedAt) {
    return false;
  }
  return moment().isBefore(moment(lockedAt));
};

export const isOwnProject = project => project.tracker === "own";

export const snakeCase = value =>
  value.replace(/([A-Z])/g, item => `_${item[0].toLowerCase()}`);

export const titleize = value =>
  value
    .split(" ")
    .map(item => `${item[0].toUpperCase()}${item.slice(1)}`)
    .join(" ");

export const transformKeys = (data, transformer) => {
  let transformed;
  if (Array.isArray(data)) {
    transformed = data.map(item => transformKeys(item, transformer));
  } else if (isObject(data)) {
    transformed = {};
    Object.keys(data).forEach(key => {
      transformed[transformer(key)] = transformKeys(data[key], transformer);
    });
  }
  return transformed || data;
};
