/**
 * Project Name
 * ============
 *
 * When slug is present - prepend it in badge before project name.
 *
 */

import React, { Fragment } from "react";

import { TRACKER_OWN } from "../constants";
import { ProjectPropType } from "../propTypes";

const ProjectName = ({ data: { name, slug, tracker } }) => (
  <Fragment>
    {slug ? (
      <span className="badge badge-project badge-secondary mr-1">{slug}</span>
    ) : null}
    {tracker !== TRACKER_OWN ? (
      <span className="badge badge-project badge-secondary mr-1">
        {tracker}
      </span>
    ) : null}
    {name}
  </Fragment>
);

ProjectName.propTypes = {
  data: ProjectPropType.isRequired
};

export default ProjectName;
