/**
 * Help Text
 * ---------
 *
 * Render help text for form input or other component.
 *
 */

import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const HelpText = ({ children, isInForm }) => (
  <span className={classnames("text-muted", { "form-text": isInForm })}>
    {children}
  </span>
);

HelpText.defaultProps = {
  isInForm: false
};
HelpText.propTypes = {
  children: PropTypes.node.isRequired,
  isInForm: PropTypes.bool
};

export default HelpText;
