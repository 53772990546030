/* eslint-disable react/no-array-index-key */
import classnames from "classnames";
import I from "immutable";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import qs from "qs";
import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";

import { DATE_FORMAT, SPAN_LABELS, TRUE_STR } from "../constants";
import { StopStoriesFiltersDataPropType } from "../propTypes";
import { hasDateRangeFilter } from "../records";
import { getLocationQuery } from "../utils";

const State = new I.Record({
  dateFrom: "",
  dateTo: ""
});

export const getPeriodFilterLabel = data => {
  if (hasDateRangeFilter(data)) {
    const dateFrom = data.dateFrom ? moment(data.dateFrom) : null;
    const dateTo = data.dateTo ? moment(data.dateTo) : null;

    const parts = [];
    if (dateFrom) {
      parts.push(`from ${dateFrom.format(DATE_FORMAT)}`);
    }

    if (dateTo) {
      parts.push(`to ${dateTo.format(DATE_FORMAT)}`);
    }

    return parts.join(" ");
  }

  const { prev, span } = data;
  const isPrevious = prev === TRUE_STR;

  if (span === "day") {
    return isPrevious ? "yesterday" : "today";
  }
  return `${isPrevious ? "last" : "this"} ${SPAN_LABELS.get(span)}`;
};

const StopStoriesPeriodFilter = ({ data, history, location }) => {
  const [state, setState] = useState(
    new State({
      dateFrom: data.dateFrom || "",
      dateTo: data.dateTo || ""
    })
  );

  const clearState = () => {
    setState(new State());
  };

  const { pathname } = location;
  const query = getLocationQuery(location);

  const isPrevious = data.prev === TRUE_STR;
  const hasDateRange = hasDateRangeFilter(data);

  return (
    <div className="py-2">
      <div className="d-flex flex-wrap px-3">
        <span className="text-muted mr-2">Show stories for:</span>
        <Link
          className={classnames("mr-2", {
            "font-weight-bold": !hasDateRange && !isPrevious
          })}
          onClick={clearState}
          to={{
            pathname,
            search: `?${qs.stringify({
              ...query,
              prev: undefined,
              from: undefined,
              to: undefined
            })}`
          }}
        >
          this
        </Link>
        <Link
          className={classnames("mr-4", {
            "font-weight-bold": !hasDateRange && isPrevious
          })}
          onClick={clearState}
          to={{
            pathname,
            search: `?${qs.stringify({
              ...query,
              prev: TRUE_STR,
              from: undefined,
              to: undefined
            })}`
          }}
        >
          last
        </Link>
        {SPAN_LABELS.map((value, key) => (
          <Link
            className={classnames("mr-2", {
              "font-weight-bold": !hasDateRange && key === data.span
            })}
            key={key}
            onClick={clearState}
            to={{
              pathname,
              search: `?${qs.stringify({
                ...query,
                span: key,
                from: undefined,
                to: undefined
              })}`
            }}
          >
            {value}
          </Link>
        ))
          .valueSeq()
          .toArray()}
      </div>

      <form className="form-inline px-3 pt-2 pt-sm-0">
        <span className="text-muted mr-2">Or for custom period:</span>

        <label className="sr-only" htmlFor="fromDate">
          From Date
        </label>
        <input
          autoComplete="off"
          className="form-control form-control-sm mr-sm-2 my-1 my-sm-0"
          id="fromDate"
          max={moment().format("YYYY-MM-DD")}
          onChange={evt => {
            const { value } = evt.target;
            setState(currentState => currentState.set("dateFrom", value));
          }}
          placeholder="From Date (YYYY-MM-DD)"
          style={{ width: "8rem" }}
          type="date"
          value={state.dateFrom}
        />

        <label className="sr-only" htmlFor="toDate">
          To Date
        </label>
        <input
          autoComplete="off"
          className="form-control form-control-sm mr-sm-2 my-1 my-sm-0"
          id="toDate"
          max={moment().format("YYYY-MM-DD")}
          onChange={evt => {
            const { value } = evt.target;
            setState(currentState => currentState.set("dateTo", value));
          }}
          placeholder="To Date (YYYY-MM-DD)"
          style={{ width: "8rem" }}
          type="date"
          value={state.dateTo}
        />

        <button
          className="btn btn-primary btn-sm px-3"
          onClick={evt => {
            evt.preventDefault();

            history.push({
              pathname,
              search: `?${qs.stringify({
                ...query,
                from: state.dateFrom || undefined,
                to: state.dateTo || undefined,
                span: undefined,
                prev: undefined
              })}`
            });
          }}
          type="submit"
        >
          Apply
        </button>

        {hasDateRange ? (
          <Link
            className="ml-2"
            onClick={clearState}
            to={{
              pathname,
              string: `?${qs.stringify({
                ...query,
                from: undefined,
                to: undefined
              })}`
            }}
          >
            clear
          </Link>
        ) : null}
      </form>
    </div>
  );
};

StopStoriesPeriodFilter.propTypes = {
  data: StopStoriesFiltersDataPropType.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(StopStoriesPeriodFilter);
