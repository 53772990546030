/**
 * Timezones Datalist
 * ==================
 *
 * Timezones datalist.
 *
 */

import PropTypes from "prop-types";
import React, { Component } from "react";

export default class TimezonesList extends Component {
  static propTypes = {
    id: PropTypes.string,
    timezones: PropTypes.arrayOf(PropTypes.string).isRequired
  };

  static defaultProps = {
    id: "timezones"
  };

  renderTimezoneOption = value => {
    const { id } = this.props;
    return <option key={`${id}-${value}`} value={value} />;
  };

  render() {
    const { id, timezones } = this.props;
    return (
      <datalist id={id}>{timezones.map(this.renderTimezoneOption)}</datalist>
    );
  }
}
