import I from "immutable";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import api, { logoutOnAccessDenied } from "../api";
import { AppDataContext } from "../contexts";
import {
  hasDateRangeFilter,
  toProject,
  toStopStories,
  toStopStoriesFiltersData
} from "../records";
import { getLocationQuery, titleize } from "../utils";

import Empty from "./Empty";
import Loading from "./Loading";
import ServerError from "./ServerError";
import StopStoriesFilters from "./StopStoriesFilters";
import { getPeriodFilterLabel } from "./StopStoriesPeriodFilter";
import StopStoriesTable from "./StopStoriesTable";
import { onRefreshDataFactory } from "./utils";

export default withRouter(
  class StopStories extends Component {
    static contextType = AppDataContext;

    static propTypes = {
      location: PropTypes.object.isRequired
    };

    constructor(props) {
      super(props);

      this.state = {
        filtersData: toStopStoriesFiltersData(getLocationQuery(props.location)),
        projectsData: new I.List(),
        status: null,
        storiesData: new I.List()
      };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      const filtersData = toStopStoriesFiltersData(
        getLocationQuery(nextProps.location)
      );

      if (!filtersData.equals(prevState.filtersData)) {
        return {
          ...prevState,
          filtersData,
          projectsData: new I.List(),
          status: null,
          storiesData: new I.List()
        };
      }

      return null;
    }

    componentDidMount() {
      this.loadData();

      window.addEventListener("start_tracker", this.handleRefreshData);
      window.addEventListener("stop_tracker", this.handleRefreshData);
    }

    componentDidUpdate() {
      const { status } = this.state;
      if (status === null) {
        this.loadData();
      }
    }

    componentWillUnmount() {
      window.removeEventListener("stop_tracker", this.handleRefreshData);
      window.removeEventListener("start_tracker", this.handleRefreshData);

      this.isUnmounted = true;
    }

    isUnmounted = false;

    getUniqueProjects() {
      const { filtersData, projectsData, storiesData } = this.state;
      const { project } = filtersData;

      if (!project && storiesData.count() > 1) {
        const projects = [];
        storiesData.butLast().map(day =>
          day.stories.forEach(({ story }) => {
            projects.push([story.project.slug, story.project]);
          })
        );
        return new I.Map(projects).toList().sortBy(({ name }) => name);
      } else if (project) {
        return projectsData;
      }

      return new I.List();
    }

    handleRefreshData = evt => {
      const defaultState = {
        ...this.state,
        projectsData: new I.List(),
        status: null,
        storiesData: new I.List()
      };
      return onRefreshDataFactory(this, defaultState)(evt);
    };

    handleUpdateStory = story => {
      const {
        onLogout,
        urls: { stopStories: storiesUrl }
      } = this.context;
      const { id: storyId, ...data } = story;

      return api
        .patch(`${storiesUrl}/${storyId}`, data, {
          validateStatus: status => status === 202
        })
        .catch(logoutOnAccessDenied(onLogout))
        .then(() => {
          onRefreshDataFactory(this)();
        })
        .catch(() => {
          // FIXME: Better error handling
          onRefreshDataFactory(this)();
        });
    };

    loadData = () => {
      const {
        onLogout,
        urls: { stopProjects: projectsUrl, stopStories: storiesUrl }
      } = this.context;
      const {
        filtersData,
        filtersData: { dateFrom, dateTo, prev, project, span, story }
      } = this.state;

      const toProjectsList = apiData => new I.List(apiData.map(toProject));
      const toStoriesList = apiData => new I.List(apiData.map(toStopStories));

      const defaultParams = hasDateRangeFilter(filtersData)
        ? { dateFrom: dateFrom, dateTo: dateTo }
        : { span: span || undefined, prev: prev || undefined };

      if (project) {
        api
          .get(projectsUrl, {
            params: defaultParams
          })
          .catch(logoutOnAccessDenied(onLogout))
          .then(({ data: apiData }) => {
            if (this.isUnmounted) {
              return;
            }
            this.setState({ projectsData: toProjectsList(apiData) });
          })
          .catch(() => {
            if (this.isUnmounted) {
              return;
            }
            this.setState({ projectsData: new I.List() });
          });
      }

      return api
        .get(storiesUrl, {
          params: {
            ...defaultParams,
            project: project || undefined,
            story: story || undefined
          }
        })
        .catch(logoutOnAccessDenied(onLogout))
        .then(({ data: apiData }) => {
          if (this.isUnmounted) {
            return;
          }
          this.setState({ storiesData: toStoriesList(apiData), status: true });
        })
        .catch(() => {
          if (this.isUnmounted) {
            return;
          }
          this.setState({ storiesData: new I.List(), status: false });
        });
    };

    render() {
      const { filtersData, status, storiesData } = this.state;
      const periodLabel = !hasDateRangeFilter(filtersData)
        ? `earlier ${getPeriodFilterLabel(filtersData)}`
        : getPeriodFilterLabel(filtersData);

      let content;
      if (status === null) {
        // Loading Stories from server
        content = <Loading className="mt-3" />;
      } else if (status === false) {
        // Stories didn't loaded from server due to error
        content = (
          <ServerError onRefreshData={this.handleRefreshData}>
            previously tracked Stories
          </ServerError>
        );
      } else if (storiesData.count() > 1) {
        // Positive state. Data loaded and ready to be displayed
        content = (
          <StopStoriesTable
            defaultStories={storiesData}
            onUpdateStory={this.handleUpdateStory}
          />
        );
      } else {
        // Positive state. Data loaded, but empty
        const {
          location: { pathname, search }
        } = this.props;
        const label = search ? (
          <span>
            <Link to={pathname}>Clear filters</Link> to show all tracked
            stories.
          </span>
        ) : (
          "Start tracking new Story by clicking on button above."
        );
        content = (
          <Empty className="mt-3">
            No Stories have been tracked yet {periodLabel}. {label}
          </Empty>
        );
      }

      return (
        <div id="stop-stories">
          <h2>Tracked {titleize(periodLabel)}</h2>
          <StopStoriesFilters
            data={filtersData}
            projects={this.getUniqueProjects()}
          />
          {content}
        </div>
      );
    }
  }
);
