/**
 * Tracker
 * =======
 *
 * Smart component for showing currently tracking stories for user.
 *
 */

import I from "immutable";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";

import api, { logoutOnAccessDenied } from "../api";
import { KEY_REFRESH, KEY_START, KEY_STOP } from "../constants";
import { AppDataContext } from "../contexts";
import { toTrackedStory } from "../records";

import ButtonsRow from "./ButtonsRow";
import CancelButton from "./CancelButton";
import Empty from "./Empty";
import Loading from "./Loading";
import PageHeader from "./PageHeader";
import ServerError from "./ServerError";
import SubmitButton from "./SubmitButton";
import TrackingStoriesTable from "./TrackingStoriesTable";

export default withRouter(
  class Tracker extends Component {
    static contextType = AppDataContext;

    static defaultProps = {
      interval: 60000
    };

    static defaultState = {
      data: new I.List(),
      isStopping: false,
      status: null
    };

    static propTypes = {
      history: PropTypes.object.isRequired,
      interval: PropTypes.number
    };

    state = Tracker.defaultState;

    componentDidMount() {
      this.loadData();
      this.setInterval();

      document.addEventListener("keydown", this.handleKeyDown);
      window.addEventListener("start_tracker", this.handleRefreshData);
      window.addEventListener("stop_tracker", this.handleRefreshData);
    }

    componentWillUnmount() {
      window.removeEventListener("stop_tracker", this.handleRefreshData);
      window.removeEventListener("start_tracker", this.handleRefreshData);
      document.removeEventListener("keydown", this.handleKeyDown);

      this.clearInterval();
      this.isUnmounted = true;
    }

    intervalId = null;

    isUnmounted = false;

    clearInterval = () => {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    };

    loadData = () => {
      const {
        onLogout,
        urls: { tracker: url }
      } = this.context;
      const toList = data => new I.List(data.map(toTrackedStory));

      api
        .get(url)
        .catch(logoutOnAccessDenied(onLogout))
        .then(({ data: apiData }) => {
          if (this.isUnmounted) {
            this.clearInterval();
            return;
          }
          this.setState({ data: toList(apiData), status: true });
        })
        .catch(() => {
          if (this.isUnmounted) {
            this.clearInterval();
            return;
          }
          if (this.intervalId) {
            this.clearInterval();
          }
          this.setState({ data: new I.List(), status: false });
        });
    };

    handleKeyDown = evt => {
      const { history } = this.props;
      const { data } = this.state;
      const { code } = evt;

      if (code === KEY_REFRESH) {
        return this.handleRefreshData();
      }

      if (code === KEY_STOP && data.count()) {
        return this.handleStopStory();
      }

      if (code === KEY_START) {
        return history.push("/app/tracker");
      }
    };

    handleRefreshData = evt => {
      if (evt) {
        evt.preventDefault();
      }

      this.clearInterval();
      this.setState(Tracker.defaultState, () => {
        this.loadData();
        this.setInterval();
      });
    };

    handleStopStory = evt => {
      if (evt) {
        evt.preventDefault();
      }

      this.setState({ isStopping: true });

      const {
        urls: { tracker: url }
      } = this.context;

      api
        .delete(url, {
          validateStatus: status => status === 204
        })
        .then(() => {
          window.dispatchEvent(new Event("stop_tracker"));
        })
        .catch(() => {
          // FIXME: Proper error handling
          this.setState({
            isStopping: false
          });
        });
    };

    setInterval = () => {
      const { interval } = this.props;
      this.intervalId = setInterval(this.loadData, interval);
    };

    render() {
      const { data, isStopping, status } = this.state;

      let content;
      let stopButton;

      if (status === null) {
        // Default state. Pre-loading data
        content = <Loading as="p" />;
      } else if (status === false) {
        // Negative state. Data not loaded from server due to error
        content = (
          <ServerError onRefreshData={this.handleRefreshData}>
            current tracking Story
          </ServerError>
        );
      } else if (data.count()) {
        // Positive state. Data loaded and ready to be shown
        content = <TrackingStoriesTable stories={data} />;
        stopButton = (
          <CancelButton
            disabled={isStopping}
            onClick={this.handleStopStory}
            order={2}
          >
            Stop Current Story
          </CancelButton>
        );
      } else {
        // Positive state. Data loaded, but empty
        content = (
          <Empty>
            No Story is tracking right now. Start tracking new Story by clicking
            on button below.
          </Empty>
        );
      }

      return (
        <Fragment>
          <PageHeader>Now Tracking</PageHeader>
          {content}
          <ButtonsRow className="justify-content-between">
            {stopButton}
            <SubmitButton hasOffset={false} order={1} to="/app/tracker">
              Track New Story
            </SubmitButton>
          </ButtonsRow>
        </Fragment>
      );
    }
  }
);
